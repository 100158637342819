import React, { useCallback, useState } from 'react'
import styles from './styles.module.scss'
import Subtitles from '../Subtitles/Subtitles'
import { ID_HOW_IT_WORKS, functionsData } from 'pages/landingNew/lib/constants'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CrossBtn } from '../../assets/icons/crossBtnIcon.svg'
import { useMediaQuery } from 'lib/hooks/useMediaQuery'
import ModalVideo from '../ModalVideo/ModalVideo'

const generateId = () => Math.random().toString(36).substring(2, 12)

interface IModalItem {
  id: string
  src: string
}
const FunctionScreen = () => {
  const { t } = useTranslation()

  const [modalsList, setModalsList] = useState<IModalItem[]>([])

  const isMobile = useMediaQuery('(max-width: 830px)')

  const handleModalOpen = (src: string) => {
    if (modalsList.length === 3 || isMobile) {
      return setModalsList([{ id: generateId(), src }])
    }
    setModalsList([...modalsList, { id: generateId(), src }])
  }

  const handleModalClose = useCallback(
    (id: string) => {
      setModalsList(modalsList.filter((i) => i.id !== id))
    },
    [modalsList],
  )

  return (
    <section className={styles.function} id={ID_HOW_IT_WORKS}>
      <Subtitles variant='function' />
      <ul className={styles.function__container}>
        {functionsData.map((i) => (
          <li key={i.id} className={styles.item}>
            {!isMobile && (
              <h3 className={styles.item__title}>
                {t(i.localeTitle)}
                <CrossBtn className={styles.item__btn} role='button' onClick={() => handleModalOpen(i.src)} />
              </h3>
            )}

            {isMobile && (
              <div className={styles.item__wrapper}>
                <h3 className={styles.item__title}>{t(i.localeTitle)}</h3>
                <CrossBtn className={styles.item__btn} role='button' onClick={() => handleModalOpen(i.src)} />
              </div>
            )}

            {t(i.localeDescription) && <p className={styles.item__description}>{t(i.localeDescription)}</p>}
          </li>
        ))}
      </ul>
      {modalsList.map((i, ind) => (
        <ModalVideo
          key={i.id}
          src={i.src}
          id={i.id}
          onClose={handleModalClose}
          isPaused={modalsList.length - 1 !== ind}
          sequenceNum={ind}
        />
      ))}
    </section>
  )
}

export default FunctionScreen
