import { translationTablesComponentsRU, translationTablesComponentsEN } from './components'
import translationGeneralTableEN from './GeneralTable/en.json'
import translationGeneralTableRU from './GeneralTable/ru.json'
import translationMoveLocationTableEN from './MoveLocationTable/en.json'
import translationMoveLocationTableRU from './MoveLocationTable/ru.json'
import translationMemberTableEN from './MemberTable/en.json'
import translationMemberTableRU from './MemberTable/ru.json'
import translationMemberCreateFormEN from './MemberTable/components/MemberCreateForm/en.json'
import translationMemberCreateFormRU from './MemberTable/components/MemberCreateForm/ru.json'
import translationMemberRedactFormEN from './MemberTable/components/MemberRedactForm/en.json'
import translationMemberRedactFormRU from './MemberTable/components/MemberRedactForm/ru.json'

export const translationTablesEN = {
  ...translationGeneralTableEN,
  ...translationMoveLocationTableEN,
  ...translationTablesComponentsEN,
  ...translationMemberTableEN,
  ...translationMemberCreateFormEN,
  ...translationMemberRedactFormEN,
}
export const translationTablesRU = {
  ...translationGeneralTableRU,
  ...translationMoveLocationTableRU,
  ...translationTablesComponentsRU,
  ...translationMemberTableRU,
  ...translationMemberCreateFormRU,
  ...translationMemberRedactFormRU,
}
