import React from 'react'

export const moveLeft = () => (
  <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3701_93091)'>
      <path
        d='M11.3364 7.51025L14.2839 4.56281L17.2313 7.51025'
        stroke='#6A6E74'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.2839 10.4577V4.56281'
        stroke='#6A6E74'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.2313 20.7739L14.2839 23.7214L11.3364 20.7739'
        stroke='#6A6E74'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.2839 23.7214V17.8265'
        stroke='#6A6E74'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.9157 17.0895L23.8631 14.1421L20.9157 11.1946'
        stroke='#6A6E74'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.9683 14.1421H23.8631'
        stroke='#6A6E74'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.65203 11.1946L4.70459 14.1421L7.65203 17.0895'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M4.70459 14.1421H10.5995'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </g>
    <defs>
      <clipPath id='clip0_3701_93091'>
        <rect width='20' height='20' fill='white' transform='translate(0 14.1421) rotate(-45)' />
      </clipPath>
    </defs>
  </svg>
)
