import translationSearchedListEN from './SearchedList/en.json'
import translationSearchedListRU from './SearchedList/ru.json'
import translationAddMemberInputEN from './AddMemberInput/en.json'
import translationAddMemberInputRU from './AddMemberInput/ru.json'

export const translationAddMemberNewComponentsEN = {
  ...translationSearchedListEN,
  ...translationAddMemberInputEN,
}
export const translationAddMemberNewComponentsRU = {
  ...translationSearchedListRU,
  ...translationAddMemberInputRU,
}
