import React from 'react'

export const zoomIn = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' fill='none'>
    <path
      fill='#fff'
      d='M14.9295 10.3829h-2.7656V7.2422a.188.188 0 0 0-.1875-.1875h-1.4063a.188.188 0 0 0-.1875.1875v3.1407H7.617a.188.188 0 0 0-.1875.1875v1.4062a.188.188 0 0 0 .1875.1875h2.7656v3.1406a.188.188 0 0 0 .1875.1875h1.4063a.188.188 0 0 0 .1875-.1875v-3.1406h2.7656a.188.188 0 0 0 .1875-.1875v-1.4062a.188.188 0 0 0-.1875-.1875Zm6.6562 9.9375-3.4218-3.4219c2.8617-3.4899 2.6625-8.6602-.6094-11.9297-3.4688-3.4711-9.1031-3.4711-12.586 0-3.471 3.4828-3.471 9.1172 0 12.5859 3.2696 3.2719 8.4399 3.4711 11.9297.6094l3.4219 3.4219c.075.0656.1945.0656.2578 0l1.0078-1.0078c.0657-.0633.0657-.1828 0-.2578Zm-5.2734-4.0079c-2.7844 2.7821-7.2937 2.7821-10.0781 0-2.782-2.7843-2.782-7.2937 0-10.078 2.7844-2.7821 7.2937-2.7821 10.0781 0 2.782 2.7843 2.782 7.2937 0 10.078Z'
    />
  </svg>
)
