import { translationAddMemberNewComponentsRU, translationAddMemberNewComponentsEN } from './components'
import translationAddMemberNewComponentEN from './en.json'
import translationAddMemberNewComponentRU from './ru.json'

export const translationAddMemberNewEN = {
  ...translationAddMemberNewComponentEN,
  ...translationAddMemberNewComponentsEN,
}
export const translationAddMemberNewRU = {
  ...translationAddMemberNewComponentRU,
  ...translationAddMemberNewComponentsRU,
}
