import React from 'react'
import styles from './styles.module.scss'

const LeftDecor = () => {
  const svgArray = [
    <svg
      className={styles.svgSign}
      key={0}
      xmlns='http://www.w3.org/2000/svg'
      width='40'
      height='19'
      viewBox='0 0 40 19'
      fill='none'
    >
      <path
        d='M30.002 4.6376C33.7032 4.6376 35.0051 7.14043 35.0051 9.27519C35.0051 11.41 33.7032 13.9128 30.002 13.9128C27.8485 13.9128 25.5558 11.7289 23.304 9.27519C25.6049 6.81326 27.8567 4.6376 30.002 4.6376ZM9.99795 4.6376C12.0942 4.6376 14.3951 6.82144 16.696 9.27519C14.3951 11.7371 12.0942 13.9128 9.99795 13.9128C6.29683 13.9128 4.99488 11.41 4.99488 9.27519C4.99488 7.14043 6.29683 4.6376 9.99795 4.6376ZM9.99795 0C3.44729 0 0 4.68667 0 9.27519C0 13.8637 3.44729 18.5504 9.99795 18.5504C13.9447 18.5504 17.0972 15.9494 19.9959 12.8904C22.8946 15.9494 25.9488 18.5504 29.9939 18.5504C36.5445 18.5504 39.9918 13.8637 39.9918 9.27519C39.9918 4.68667 36.5527 0 30.002 0C25.9488 0 22.9028 2.60098 20.0041 5.65999C17.0972 2.60098 13.9529 0 9.99795 0Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={1}
      xmlns='http://www.w3.org/2000/svg'
      width='24'
      height='16'
      viewBox='0 0 24 16'
      fill='none'
    >
      <path
        d='M14.9263 1.84894V0.785644H21.2968V1.84894H22.3613V2.91223H23.4258V13.5206H22.3777V14.5921H21.305V15.639H14.9345V14.5757H13.87V13.5125H12.8055V2.90405H13.87V1.84076H14.9345L14.9263 1.84894ZM14.9263 11.394V12.4573H15.9908V13.5206H20.2487V12.4737H21.2968V7.15723H20.2323V8.22052H19.1678V9.28382H18.1033V10.3471H17.0389V11.4104H14.9181L14.9263 11.394ZM15.9908 9.27563V8.21234H17.0552V7.14905H18.1197V6.08576H19.1842V5.02246H21.305V3.95917H20.2405V2.89587H15.9908V3.95917H14.9263V9.25928H15.9908V9.27563ZM2.18523 13.5125H10.6765V15.6309H0.0644531V9.26746H1.12894V8.20416H2.19342V7.14087H7.49946V6.07757H8.56394V3.95917H7.49946V2.89587H3.24971V3.95917H2.18523V5.02246H0.0644531V2.90405H1.12894V1.84076H2.19342V0.777466H8.56394V1.84076H9.62842V2.90405H10.6929V7.14905H9.62842V8.21234H8.56394V9.27563H3.24971V10.3389H2.18523V13.5206V13.5125Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={2}
      xmlns='http://www.w3.org/2000/svg'
      width='22'
      height='16'
      viewBox='0 0 22 16'
      fill='none'
    >
      <path
        d='M12.9266 1.38105V0.317749H19.2971V1.38105H20.3616V2.44433H21.4261V13.0527H20.378V14.1242H19.3053V15.1711H12.9348V14.1078H11.8703V13.0446H10.8058V2.43616H11.8703V1.37286H12.9348L12.9266 1.38105ZM12.9266 10.9261V11.9894H13.9911V13.0527H18.249V12.0058H19.2971V6.68933H18.2326V7.75263H17.1681V8.81592H16.1037V9.87921H15.0392V10.9425H12.9184L12.9266 10.9261ZM13.9911 8.80774V7.74445H15.0556V6.68115H16.12V5.61786H17.1845V4.55456H19.3053V3.49128H18.2408V2.42798H13.9911V3.49128H12.9266V8.79138H13.9911V8.80774ZM4.43529 13.0527H6.55607V15.1711H0.185547V13.0527H2.30632V4.57093H0.185547V2.45252H1.25003V1.38922H2.31451V0.32593H4.43529V13.0527Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={3}
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='16'
      viewBox='0 0 11 16'
      fill='none'
    >
      <path
        d='M8.55575 2.96838H0.0644531V0.849976H10.6765V6.15008H9.62024V7.21338H8.55575V8.27667H7.49127V9.33178H6.43498V15.6952H4.30601V8.27667H5.37049V7.21338H6.43498V6.15008H7.49127V5.09497H8.55575V2.96838Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={4}
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='16'
      viewBox='0 0 11 16'
      fill='none'
    >
      <path
        d='M0.0644531 0.38208H10.6765V2.50048H2.18523V4.61889H8.55575V5.68219H9.62024V6.74548H10.6765V13.1089H9.62024V14.1722H8.55575V15.2273H2.18523V14.1722H1.12075V13.1007H0.0644531V10.9741H2.18523V12.0456H3.24971V13.1089H7.49127V12.0456H8.55575V8.86389H8.54756V7.8006H7.49127V6.74548H0.0644531V0.38208Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={5}
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='16'
      viewBox='0 0 11 16'
      fill='none'
    >
      <path
        d='M6.43498 9.39599V5.15099H5.37049V6.21429H4.30601V7.27758H3.24153V8.34088H2.17704V9.40417H6.42679L6.43498 9.39599ZM6.43498 1.96929V0.906006H8.55575V9.38781H10.6765V11.5062H8.55575V15.7512H6.43498V11.5062H0.0644531V7.26122H1.12894V6.19794H2.19342V5.13464H3.2579V4.07135H4.32239V3.00805H5.38687V1.94475H6.45135L6.43498 1.96929Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={6}
      xmlns='http://www.w3.org/2000/svg'
      width='11'
      height='16'
      viewBox='0 0 11 16'
      fill='none'
    >
      <path
        d='M1.11256 13.1649H0.0644531V11.0465H2.18523V12.1098H3.23334V13.1649H7.49127V12.1098H8.55575V9.98323H7.49127V8.9281H4.30601V6.80151H7.49127V5.74642H8.55575V3.61982H7.49127V2.5647H3.24971V3.61982H2.18523V4.68311H0.0644531V2.5647H1.12075V1.50142H2.18523V0.43811H8.55575V1.50142H9.62024V2.5647H10.6765V6.80151H9.62024V8.9281H10.6765V13.1649H9.62024V14.2282H8.55575V15.2915H2.18523V14.2282H1.11256V13.1649Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={7}
      xmlns='http://www.w3.org/2000/svg'
      width='37'
      height='16'
      viewBox='0 0 37 16'
      fill='none'
    >
      <path
        d='M25.5465 0.970223H36.1668V3.08863H27.6755V5.20703H34.046V6.27032H35.1105V7.33363H36.175V13.697H35.1105V14.7603H34.046V15.8236H27.6755V14.7603H26.611V13.6888H25.5465V11.5704H27.6673V12.6419H28.7318V13.7052H32.9815V12.6419H34.046V9.46022H34.0378V8.39691H32.9897V7.33363H25.5547V0.970223H25.5465ZM18.1115 13.697V15.8154H15.9908V13.697H18.1115ZM2.18523 13.697H10.6765V15.8154H0.0644531V9.45203H1.12894V8.38875H2.19342V7.32544H7.49946V6.26215H8.56394V4.14375H7.49946V3.08044H3.24971V4.14375H2.18523V5.20703H0.0644531V3.08863H1.12894V2.02532H2.19342V0.962036H8.56394V2.02532H9.62842V3.08863H10.6929V7.33363H9.62842V8.39691H8.56394V9.46022H3.24971V10.5235H2.18523V13.7052V13.697Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={8}
      xmlns='http://www.w3.org/2000/svg'
      width='37'
      height='16'
      viewBox='0 0 37 16'
      fill='none'
    >
      <path
        d='M27.6673 13.2291H36.1586V15.3475H25.5384V8.98411H26.6028V7.92083H27.6673V6.85754H32.9734V5.79423H34.0378V3.67583H32.9734V2.61255H28.7236V3.67583H27.6591V4.73911H25.5384V2.62071H26.6028V1.55742H27.6673V0.494141H34.0378V1.55742H35.1023V2.62071H36.1668V6.86571H35.1023V7.92901H34.0378V8.9923H28.7318V10.0556H27.6673V13.2373V13.2291ZM18.1115 13.2291V15.3475H15.9908V13.2291H18.1115ZM2.18523 13.2291H10.6765V15.3475H0.0644531V8.98411H1.12894V7.92083H2.19342V6.85754H7.49946V5.79423H8.56394V3.67583H7.49946V2.61255H3.24971V3.67583H2.18523V4.73911H0.0644531V2.62071H1.12894V1.55742H2.19342V0.494141H8.56394V1.55742H9.62842V2.62071H10.6929V6.86571H9.62842V7.92901H8.56394V8.9923H3.24971V10.0556H2.18523V13.2373V13.2291Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={9}
      xmlns='http://www.w3.org/2000/svg'
      width='35'
      height='15'
      viewBox='0 0 35 15'
      fill='none'
    >
      <path
        d='M30.9819 2.15285H26.7321V3.21613H25.6676V5.33453H26.7321V6.39784H30.9819V5.35091H32.0463V3.21613H30.9819V2.15285ZM32.0463 13.8164V14.8797H25.6758V13.8164H24.6113V12.7531H23.5469V8.50806H24.6113V6.38966H23.5469V2.14466H24.6113V1.08137H25.6758V0.0180664H32.0463V1.08137H33.1108V2.14466H34.1753V6.38966H33.1108V7.46113H33.1026V8.50806H34.1753V12.7531H33.1108V13.8164H32.0463ZM26.7403 12.7531H30.9901V11.6898H32.0545V9.55499H30.9819V8.50806H26.7485V9.57137H25.684V11.6898H26.7485V12.7531H26.7403ZM16.12 12.7531V14.8715H13.9993V12.7531H16.12ZM4.44348 12.7531H6.56426V14.8715H0.185547V12.7531H2.30632V4.27125H0.185547V2.15285H1.25003V1.08954H2.31451V0.0262536H4.43529V12.7531H4.44348Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={10}
      xmlns='http://www.w3.org/2000/svg'
      width='35'
      height='16'
      viewBox='0 0 35 16'
      fill='none'
    >
      <path
        d='M26.7321 13.2933H30.9819V12.23H32.0463V10.1116H30.9819V9.04832H25.6758V12.23H26.7403V13.2933H26.7321ZM32.0382 14.3566V15.4199H25.6676V14.3566H24.6032V13.2933H23.5387V2.68492H24.6032V1.62163H25.6676V0.55835H32.0382V1.62982H33.1026V2.67675H34.1671V4.79516H32.0463V3.74823H30.9819V2.67675H26.7321V3.74004H25.6676V6.92175H32.0382V7.98503H33.1026V9.04832H34.1671V13.2933H33.1026V14.3566H32.0382ZM16.1119 13.2933V15.4117H13.9911V13.2933H16.1119ZM4.43529 13.2933H6.55607V15.4117H0.185547V13.2933H2.30632V4.81151H0.185547V2.6931H1.25003V1.62982H2.31451V0.566512H4.43529V13.2933Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={11}
      xmlns='http://www.w3.org/2000/svg'
      width='35'
      height='15'
      viewBox='0 0 35 15'
      fill='none'
    >
      <path
        d='M29.9174 8.58057V4.33557H28.8529V5.39886H27.7884V6.46214H26.7239V7.52547H25.6594V8.58876H29.9092L29.9174 8.58057ZM29.9174 1.15386V0.0905762H32.0382V8.57238H34.1589V10.6908H32.0382V14.9358H29.9174V10.6908H23.5469V6.44582H24.6113V5.38248H25.6758V4.3192H26.7403V3.25592H27.8048V2.19263H28.8693V1.12935H29.9338L29.9174 1.15386ZM16.1119 12.8174V14.9358H13.9911V12.8174H16.1119ZM4.43529 12.8174H6.55607V14.9358H0.185547V12.8174H2.30632V4.33557H0.185547V2.21714H1.25003V1.15386H2.31451V0.0905762H4.43529V12.8174Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={12}
      xmlns='http://www.w3.org/2000/svg'
      width='35'
      height='16'
      viewBox='0 0 35 16'
      fill='none'
    >
      <path
        d='M24.595 13.3494H23.5469V11.231H25.6676V12.2943H26.7157V13.3575H30.9737V12.2943H32.0382V10.1758H30.9737V9.11255H27.7884V6.99417H30.9737V5.93084H32.0382V3.81246H30.9737V2.74918H26.7239V3.81246H25.6594V4.87574H23.5387V2.75731H24.6032V1.69403H25.6676V0.630746H32.0382V1.69403H33.1026V2.75731H34.1671V7.00231H33.1026V9.12074H34.1671V13.3657H33.1026V14.429H32.0382V15.4923H25.6676V14.429H24.595V13.3657V13.3494ZM16.1119 13.3494V15.4678H13.9911V13.3494H16.1119ZM4.43529 13.3494H6.55607V15.4678H0.185547V13.3494H2.30632V4.86756H0.185547V2.74918H1.25003V1.68584H2.31451V0.622559H4.43529V13.3494Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      className={styles.svg}
      key={13}
      xmlns='http://www.w3.org/2000/svg'
      width='35'
      height='15'
      viewBox='0 0 35 15'
      fill='none'
    >
      <path
        d='M25.6676 12.8817H34.1589V15.0001H23.5387V8.63672H24.6032V7.57339H25.6676V6.51011H30.9737V5.44682H32.0382V3.32839H30.9737V2.26511H26.7239V3.32839H25.6594V4.39173H23.5387V2.2733H24.6032V1.21001H25.6676V0.146729H32.0382V1.21001H33.1026V2.2733H34.1671V6.51829H33.1026V7.58158H32.0382V8.64486H26.7321V9.70819H25.6676V12.8899V12.8817ZM16.1119 12.8817V15.0001H13.9911V12.8817H16.1119ZM4.43529 12.8817H6.55607V15.0001H0.185547V12.8817H2.30632V4.39986H0.185547V2.28148H1.25003V1.2182H2.31451V0.154866H4.43529V12.8817Z'
        fill='#FFFF00'
      />
    </svg>,
  ]

  return (
    <div className={styles.decor}>
      <ul className={styles.decor__list}>
        {svgArray.map((item) => (
          <li key={item.key} className={styles.decor__item}>
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default LeftDecor
