import React, { useState, useCallback } from 'react'

import styles from './styles.module.scss'
import clsx from 'clsx'
import NavMenu from './NavMenu/NavMenu'
import { useMediaQuery } from 'lib/hooks/useMediaQuery'

export const Header = () => {
  const [isOpenModal, setIsOpenModal] = useState(false)
  const isMobile = useMediaQuery('(max-width: 830px)')

  const handleModalClose = useCallback(() => {
    setIsOpenModal(false)
  }, [])

  return (
    <header className={styles.wrapper}>
      <div className={styles.header}>
        <div className={styles.header__logo} />

        {!isMobile && <NavMenu />}

        {isMobile && (
          <button className={styles.burgerBtn} onClick={() => setIsOpenModal(!isOpenModal)}>
            <div className={clsx(styles.burgerBtn__topStick, isOpenModal && styles.burgerBtn__topStick_active)} />
            <div className={clsx(styles.burgerBtn__bottomStick, isOpenModal && styles.burgerBtn__bottomStick_active)} />
          </button>
        )}
      </div>
      {isMobile && (
        <div className={clsx(styles.modal, !isOpenModal && styles.modal_hidden)}>
          <NavMenu onClick={handleModalClose} variant='mobile' />
        </div>
      )}
    </header>
  )
}

export default Header
