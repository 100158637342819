import { createAsyncThunk, createSlice, isAnyOf } from '@reduxjs/toolkit'
import { api } from 'app/api'
import { IParams } from 'pages/storyboard/helpers/types'
import { apiCallSheetTypes } from 'pages/callList/redux/apiCallSheetTypes'
import { unique } from 'pages/callList/helpers/uniqueArray'

export const getCallSheetData = createAsyncThunk(
  'callSheetData/getCallSheetData',
  async (params: IParams, { rejectWithValue }) => {
    try {
      const response = await api.get<any>(`projects/${params.pk}/callsheets/${params.id}/`)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response)
    }
  },
)
// TODO проверить для чего запрос
export const patchCallsheets = createAsyncThunk('callSheetData/patchCallsheets', async (params: any) => {
  const response = await api.patch<any>(`projects/${params.pk}/callsheets/${params.id}/`, params.data)
  return response.data
})

/* export const getLocationsData = createAsyncThunk('callSheetData/Locations', async (params: IParams) => {
  const response = await api.get<any>(`projects/${params.pk}/callsheets/${params.id}/locations/`)
  return response.data
}) */

export const updateCallSheetData = createAsyncThunk(
  'callSheetData/updateCallSheetData',
  async (param: apiCallSheetTypes.IUpdateCallSheetData) => {
    const { params, updatedData } = param
    const response = await api.patch<any>(`projects/${params.pk}/callsheets/${params.id}/`, {
      ...updatedData,
    })
    return response.data
  },
)

export const updateColumnName = createAsyncThunk('callSheetData/updateColumnName', async (param: any | undefined) => {
  const { params, id, updatedData } = param
  const response = await api.patch<any>(`projects/${params.pk}/callsheets/${params.id}/usercolumns/${id}/`, {
    ...updatedData,
  })
  return response.data
})

export const updateLocationColumnName = createAsyncThunk(
  'callSheetData/updateLocationColumnName',
  async (param: any | undefined) => {
    const { params, id, updatedData, tableId } = param
    const response = await api.patch<any>(
      `projects/${params.pk}/callsheets/${params.id}/locations/${tableId}/usercolumns/${id}/`,
      {
        ...updatedData,
      },
    )
    return response.data
  },
)
export const updateUserField = createAsyncThunk(
  'callSheetData/updateCallSheetData',
  async (param: apiCallSheetTypes.IUpdateUserField) => {
    const { params, updatedData, id } = param
    const response = await api.patch<any>(`projects/${params.pk}/callsheets/${params.id}/usercolumns/${id}/`, {
      ...updatedData,
    })
    return response.data
  },
)
export const updateLocationTableData = createAsyncThunk(
  'locationData/updateLocationTableData',
  async (param: apiCallSheetTypes.IUpdateLocationTableData) => {
    const { params, updatedData, id } = param
    const response = await api.patch<any>(`projects/${params.pk}/callsheets/${params.id}/locations/${id}/`, {
      ...updatedData,
    })
    return response.data
  },
)

export const updateLogo = createAsyncThunk('locationData/updateLogo', async (param: apiCallSheetTypes.IUpdateLogo) => {
  const { params, file, id } = param
  const response = await api.updateFile<any>(`projects/${params.pk}/callsheets/${params.id}/logos/${id}/`, file)
  return response.data
})

export const addLocationTable = createAsyncThunk(
  'locations/addLocationTable',
  async (param: apiCallSheetTypes.IAddLocationTable) => {
    const { params, data } = param
    const response = await api.post<any>(`projects/${params.pk}/callsheets/${params.id}/locations/`, {
      data,
    })
    return response.data
  },
)

export const addUserField = createAsyncThunk(
  'callSheetData/addUserField',
  async (param: apiCallSheetTypes.IAddUserField) => {
    const { params, data } = param
    const response = await api.post<any>(`projects/${params.pk}/callsheets/${params.id}/usercolumns/`, {
      ...data,
    })
    return response.data
  },
)

export const addLocationUserField = createAsyncThunk(
  'locations/addLocationUserField',
  async (param: apiCallSheetTypes.IAddLocationUserField) => {
    const { params, tableId, data } = param
    const response = await api.post<any>(
      `projects/${params.pk}/callsheets/${params.id}/locations/${tableId}/usercolumns/`,
      {
        ...data,
      },
    )
    return response.data
  },
)

export const deleteLocationTable = createAsyncThunk(
  'locations/deleteLocationTable',
  async (param: apiCallSheetTypes.IDeleteLocationTable) => {
    const { params, tableId } = param
    const response = await api.delete<any>(`projects/${params.pk}/callsheets/${params.id}/locations/${tableId}/`)
    return response.data
  },
)

export const deleteLocationUserField = createAsyncThunk(
  'callSheetData/deleteUserField',
  async (param: apiCallSheetTypes.IDeleteLocationUserField) => {
    const { params, tableId, id } = param
    const response = await api.delete<any>(
      `projects/${params.pk}/callsheets/${params.id}/locations/${tableId}/usercolumns/${id}/`,
    )
    return response.data
  },
)

export const deleteUserField = createAsyncThunk(
  'locations/deleteLocationUserField',
  async (param: apiCallSheetTypes.IDeleteUserField) => {
    const { params, id } = param
    const response = await api.delete<any>(`projects/${params.pk}/callsheets/${params.id}/usercolumns/${id}/`)
    return response.data
  },
)

export const deleteLogo = createAsyncThunk('locations/deleteLogo', async (param: apiCallSheetTypes.IDeleteLogo) => {
  const { params, id } = param
  const response = await api.delete<any>(`projects/${params.pk}/callsheets/${params.id}/logos/${id}/`)
  return response.data
})

export const deleteLocationImage = createAsyncThunk('locations/deleteLocationImage', async (param: any | undefined) => {
  const response = await api.delete<any>(
    `projects/${param.params.pk}/callsheets/${param.params.id}/locations/${param.tableId}/maps/${param.elId}/`,
  )
  return response.data
})

export const updateLocationUserField = createAsyncThunk(
  'locations/updateLocationUserField',
  async (param: apiCallSheetTypes.IUpdateLocationUserField) => {
    const { params, id, updatedData } = param
    const response = await api.patch<any>(`projects/${params.pk}/callsheets/${params.id}/usercolumns/${id}/`, {
      ...updatedData,
    })
    return response.data
  },
)

export const uploadFile = createAsyncThunk('locations/uploadFile', async (param: any | undefined) => {
  const response = await api.sendFile<any>(
    `projects/${param.params.pk}/callsheets/${param.params.id}/logos/`,
    param.file,
  )
  return response.data
})
// добавить фото в локации вызвного
export const uploadLocationFile = createAsyncThunk('locations/uploadLocationFile', async (param: any | undefined) => {
  const response = await api.sendFile<any>(
    `projects/${param.params.pk}/callsheets/${param.params.id}/locations/${param.tableId}/maps/`,
    param.file,
  )
  return response.data
})

export const reorderMoveTables = createAsyncThunk('locations/reorderMoveTables', async (param: any | undefined) => {
  const { params, updatedData } = param
  const url = `projects/${params.pk}/callsheets/${params.id}/`
  const response = await api.patch(url, updatedData)
  return response.data
})
// добавить новый контакт в вызывной
export const addNewMemberToCallSheet = createAsyncThunk(
  'table/addNewMemberToCallSheet',
  async (param: any | undefined) => {
    const response = await api.post<any>(`projects/${param.pk}/callsheets/${param.id}/members/`, {})
    return response.data
  },
)
// удалить контакт из вызывного
export const deleteMember = createAsyncThunk('table/deleteMember', async (param: any | undefined) => {
  const { params, contactId } = param
  const response = await api.delete<any>(`projects/${params.pk}/callsheets/${params.id}/members/${contactId}/`)
  return response.data
})
// редактироать контакт вызывного
export const updateMemberData = createAsyncThunk('table/updateMemberData', async (param: any | undefined) => {
  const { params, contactId, updatedData } = param
  const response = await api.patch<any>(`projects/${params.pk}/callsheets/${params.id}/members/${contactId}/`, {
    ...updatedData,
  })
  return response.data
})
// скачать pdf вызывного
export const getPdfCallsheet = createAsyncThunk('table/getPdfCallsheet', async (params: any) => {
  try {
    const response = await api.getFile<any>(`projects/${params.pk}/callsheets/${params.id}/download_pdf/`)
    const fileUrl = window.URL.createObjectURL(new Blob([response.data as BlobPart]))
    const alink = document.createElement('a')
    alink.href = fileUrl
    alink.download = `${params.name}.pdf`
    alink.click()
    return response.data
  } catch (error) {
    return console.log(error)
  }
})

export const tableSlice = createSlice({
  name: 'table',
  initialState: {
    callSheetData: { members: [] },
    locationTables: [],
    locationOrder: [],
    logoList: [],
    // sortedContacts: {},
    isUpload: true,
    userRole: '',
    openTable: [],
  } as any,
  reducers: {
    setCallSheetData(state, { payload }) {
      state.callSheetData = { ...state.callSheetData, ...payload }
    },
    addNumberTable(state, { payload }) {
      state.openTable = [...state.openTable, payload]
      state.openTable = unique(state.openTable)
    },
    deleteLastOpenTable(state) {
      const newArray = state.openTable
      newArray.pop(1)
      state.openTable = newArray
    },
    setLogo: (state, action) => {
      state.logoList = action.payload
    },
    addLogo: (state, action) => {
      state.logoList.push(action.payload)
    },
    addImage: (state, action) => {
      state.logoList.push(action.payload)
    },
    deleteLocationTables: (state, action) => {
      state.locationTables = state.locationTables.filter((tb) => tb.id !== action.payload)
    },
    setUserFields: (state, action) => {
      state.callSheetData.userfields = action.payload
    },
    setLocationUserFields: (state, action) => {
      state.locationTables[0].userfields.push(action.payload)
    },
    deleteLocationUserFields: (state, action) => {
      state.locationTables[0].userfields = action.payload
    },
    deleteMoveLocationUserFields: (state, action) => {
      const { tableId, newData } = action.payload
      const tableIndex = state.locationTables.findIndex((table) => table.id === tableId)
      state.locationTables[tableIndex].userfields = newData
    },
    setMoveLocationUserFields: (state, action) => {
      const { tableId, item } = action.payload
      const tableIndex = state.locationTables.findIndex((table) => table.id === tableId)
      state.locationTables[tableIndex].userfields.push(item)
    },
  },
  extraReducers: (builder) => {
    builder.addCase(updateCallSheetData.fulfilled, (state, { payload }) => {
      state.callSheetData = payload
      state.callSheetData.members = payload.members
    })
    builder.addCase(updateCallSheetData.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(updateLocationTableData.fulfilled, (state, { payload }) => {
      state.locationTables = state.locationTables.map((tb) => (tb.id === payload.id ? payload : tb))
    })
    builder.addCase(updateLocationTableData.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(addLocationTable.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(addLocationTable.fulfilled, (state, { payload }) => {
      state.locationTables = [...state.locationTables, payload]
    })
    builder.addCase(addUserField.fulfilled, (state, { payload }) => {
      state.callSheetData.userfields.push(payload)
    })
    builder.addCase(addUserField.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(addLocationUserField.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(uploadFile.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(uploadLocationFile.pending, (state) => {
      state.isUpload = false
    })
    builder.addCase(uploadLocationFile.fulfilled, (state) => {
      state.isUpload = true
    })
    builder.addCase(uploadLocationFile.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(deleteUserField.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(updateLogo.fulfilled, (state, { payload }) => {
      state.logoList = state.logoList.map((logo) => (logo.id === payload.id ? payload : logo))
    })
    builder.addCase(updateLogo.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(deleteLocationTable.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(deleteLocationImage.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(deleteLogo.fulfilled, (state, { payload }) => {
      state.logoList = payload
    })
    builder.addCase(addNewMemberToCallSheet.fulfilled, (state, { payload }) => {
      state.callSheetData.members = [...state.callSheetData.members, payload]
    })

    builder.addCase(deleteMember.fulfilled, (state, action) => {
      state.callSheetData.members = state.callSheetData.members.filter((mem) => mem.id !== action.meta.arg.contactId)
    })
    builder.addCase(deleteMember.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(updateMemberData.fulfilled, (state, { payload }) => {
      state.callSheetData.members = state.callSheetData.members.map((el) => {
        if (payload.id === el.id) {
          return payload
        } else {
          return el
        }
      })
    })
    builder.addCase(reorderMoveTables.fulfilled, (state, { payload }) => {
      state.callSheetData = payload
    })
    builder.addCase(reorderMoveTables.rejected, (state, { error }) => {
      throw new Error(error.message)
    })

    builder.addMatcher(isAnyOf(getCallSheetData.fulfilled, patchCallsheets.fulfilled), (state, { payload }) => {
      state.callSheetData = payload
      /* const sort = payload.members?.slice().sort((a: IMember, b: IMember) => a?.department - b?.department)
      state.sortedContacts = sort.reduce((acc, item) => {
        acc[item.departmentTitle] = sort.filter((i) => i.departmentTitle === item.departmentTitle)
        return acc
      }, {}) */
      state.logoList = payload.logos
      // state.locationTables = locationsOrder(payload.locations, payload.locationOrder)
      state.locationTables = payload.locations
      state.locationOrder = payload.locationOrder
      state.userRole = payload.perm
    })
    builder.addMatcher(isAnyOf(getCallSheetData.rejected, patchCallsheets.rejected), (state, { payload }: any) => {
      throw payload
    })
  },
})

export const {
  setCallSheetData,
  addNumberTable,
  deleteLastOpenTable,
  setLogo,
  deleteLocationTables,
  setUserFields,
  setLocationUserFields,
  setMoveLocationUserFields,
  deleteLocationUserFields,
  deleteMoveLocationUserFields,
  addLogo,
  addImage,
} = tableSlice.actions

export default tableSlice.reducer
