import { IContact } from './../apiTypes'
// eslint-disable-next-line import/named
import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from '../api'

export const getContactsData: any = createAsyncThunk('contacts/get', async function async() {
  const response = await api.get('contacts/')
  return response.data
})
// создать пустой контакт в СГ
export const postContactsData: any = createAsyncThunk('contact/post', async function async(data: any) {
  const response = await api.post('contacts/', data)
  return response.data
})
// создать пустой контакт в проект, который добавиться с СГ
export const postNewContactInProject = createAsyncThunk('contacts/postNewContactInProject', async (id: any) => {
  const response = await api.post<any>(`projects/${id}/contacts/`, {})
  return response.data
})
// добавить в проект из СГ
export const putProjectNewContact = createAsyncThunk('project/putProjectNewContact', async (payload: any) => {
  const response = await api.put<any>(
    `projects/${payload.projectId}/contacts/${payload.contactId}/`,
    payload.contactData,
  )
  return response.data
})
/* export const putProjectNewContact = createAsyncThunk('project/putProjectNewContact', async (payload: any) => {
  const response = await api.put<any>(`projects/${payload.projectId}/contacts/`, payload.data)
  return response.data
}) */
// редактировать контакт (синхронно СГ и проект)
export const updateContact: any = createAsyncThunk('contact/update', async function async(param: any) {
  const { id, data } = param
  const response = await api.patch(`contacts/${id}/`, data)
  return response.data
})
// сменить порядок контактов в СГ
export const setOrderContacts = createAsyncThunk('contacts/deleteInvitedUser', async (data: any) => {
  const response = await api.post<any>(`contacts/order/`, { contacts_order: data })
  return response.data
})
// сменить порядок контактов в проекте
export const setOrderContactsInProject = createAsyncThunk(
  'contacts/setOrderContactsInProject',
  async (payload: any) => {
    const response = await api.post<any>(`projects/${payload.id}/contacts/order/`, { contacts_order: payload.data })
    return response.data
  },
)
// удалить контакт из СГ
export const deleteContact = createAsyncThunk('project/deleteContact', async (payload: any) => {
  const response = await api.deleteWithData(`contacts/0/`, payload)
  return response.data
})
// удалить контакт из проекта
export const deleteProjectContact = createAsyncThunk('project/deleteProjectContact', async (payload: any) => {
  const response = await api.deleteWithData<any>(`projects/${payload.id}/contacts/0/`, payload.data)
  return response.data
})
export const getDepartments: any = createAsyncThunk('contact/department/get', async function async(id: any) {
  const response = await api.get(`contacts/${id}/departments/`)
  return response.data
})

export const postDepartment: any = createAsyncThunk('contact/department/post', async function async(param: any) {
  const { id, data } = param
  const response = await api.post(`contacts/${id}/departments/`, data)
  return response.data
})

export const updateDepartment: any = createAsyncThunk('contact/department/update', async function async(param: any) {
  const { id, data } = param
  const response = await api.patch(`contacts/0/departments/${id}/`, data)
  return response.data
})

export const getDepartment: any = createAsyncThunk('contact/getDepartment', async function async(param: any) {
  const { id } = param
  const response = await api.get(`contacts/0/departments/${id}/`)
  return response.data
})

export const getPositions: any = createAsyncThunk('contact/positions/get', async function async(id: any) {
  const response = await api.get(`contacts/${id}/positions/`)
  return response.data
})

export const postPosition: any = createAsyncThunk('contact/positions/post', async function async(param: any) {
  const { id, data } = param
  const response = await api.post(`contacts/${id}/positions/`, data)
  return response.data
})

export const updatePosition: any = createAsyncThunk('contact/position/update', async function async(param: any) {
  const { id, data } = param
  const response = await api.patch(`contacts/0/positions/${id}/`, data)
  return response.data
})

export const getPosition: any = createAsyncThunk('contact/getPosition', async function async(param: any) {
  const { id } = param
  const response = await api.get(`contacts/0/positions/${id}/`)
  return response.data
})

export const deletePosition: any = createAsyncThunk('contact/position/delete', async function async(id) {
  const response = await api.delete(`contacts/0/positions/${id}/`)
  return response.data
})

export const deleteDepartment: any = createAsyncThunk('contact/department/delete', async function async(id) {
  const response = await api.delete(`contacts/0/departments/${id}/`)
  return response.data
})

//
// все что связано с проектом
export const getTheProject = createAsyncThunk('project/getProject', async (id: number) => {
  const response = await api.get<any>(`projects/${id}/`)
  return response.data
})

export const deleteProject = createAsyncThunk('project/deleteProject', async (id: string) => {
  const response = await api.delete<any>(`projects/${id}/`)
  return response.data
})

export const updateProject = createAsyncThunk('project/updateProject', async (payload: any) => {
  const response = await api.updateFile<any>(`projects/${payload.projectId}/`, payload.body)
  return response.data
})
export const updateProjectContact = createAsyncThunk('project/updateProjectContact', async (payload: any) => {
  const response = await api.patch<any>(`projects/${payload.projectId}/`, payload.body)
  return response.data
})

export const updateProjectIcon = createAsyncThunk('project/updateProject', async (payload: any) => {
  const response = await api.patch<any>(`projects/${payload.projectId}/`, { documentLogoUrl: payload.url })
  return response.data
})

export const postNewProject = createAsyncThunk('project/postNewProject', async (payload: any) => {
  const response = await api.sendFile<any>(`projects/`, payload.body)
  return response.data
})

export const contactSlice = createSlice({
  name: 'contacts',
  initialState: {
    contactsData: [] as IContact[],
    contactsOrder: [],
    contactsDepartment: [],
    contactsPosition: [],
    aboutProject: {
      id: null,
      name: '',
      description: '',
      documentLogo: null,
      contacts: [],
      invites: undefined,
    },
    projectImage: null,
    contact: { name: '', phone: '', email: '', position: '', department: '' },
    contactsInNonExistingProject: [],
    status: null,
    error: null,
    contactsProject: [],
    contactsOrderInProject: [],
  },
  reducers: {
    setColorDepartment(state, { payload }) {
      state.contactsData = state.contactsData.map((el) => {
        if (el.department?.id === payload.idItem && el.department !== undefined) {
          return { ...el, department: { ...el.department, tagColor: payload.color.color } }
        }
        return el
      })
      state.contactsDepartment = state.contactsDepartment.map((el) => {
        if (el.id === payload.idItem) {
          return { ...el, tagColor: payload.color.color }
        }
        return el
      })
    },
    setColorPosition(state, { payload }) {
      state.contactsData = state.contactsData.map((el) => {
        if (el.position?.id === payload.idItem && el.position !== undefined) {
          return { ...el, position: { ...el.position, tagColor: payload.color.color } }
        }
        return el
      })
      state.contactsPosition = state.contactsPosition.map((el) => {
        if (el.id === payload.idItem) {
          return { ...el, tagColor: payload.color.color }
        }
        return el
      })
    },
    setContact(state, { payload }) {
      state.contact = payload
    },
    setContactsInNonExistingProject(state, { payload }) {
      state.contactsInNonExistingProject = payload
    },
    setDefaultValues(state) {
      state.aboutProject = {
        id: null,
        name: '',
        description: '',
        documentLogo: null,
        contacts: [],
        invites: undefined,
      }
    },
    onChangeProjectName(state, { payload }) {
      state.aboutProject.name = payload
    },
    onChangeProjectDescription(state, { payload }) {
      state.aboutProject.description = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getContactsData.fulfilled, (state, { payload }) => {
      state.contactsData = payload.contacts
      state.contactsOrder = payload.contactsOrder
    })
    builder.addCase(getContactsData.rejected, () => {
      console.error('Error')
    })
    builder.addCase(postContactsData.rejected, () => {
      console.error('Error')
    })

    builder.addCase(postContactsData.fulfilled, (state, { payload }) => {
      state.contactsData = [...state.contactsData, payload]
    })
    builder.addCase(deleteContact.fulfilled, (state, action: PayloadAction<any>) => {
      state.contactsData = action.payload.contacts
    })
    builder.addCase(deleteProjectContact.fulfilled, (state, action: PayloadAction<any>) => {
      state.contactsProject = action.payload.contacts
    })
    builder.addCase(updateContact.rejected, () => {
      console.error('Error')
    })
    builder.addCase(updateContact.fulfilled, (state, { payload }) => {
      state.contactsData = state.contactsData.map((el) => {
        if (payload.id === el.id) {
          return payload
        } else {
          return el
        }
      })
      state.contactsProject = state.contactsProject.map((el) => {
        if (payload.id === el.id) {
          return payload
        } else {
          return el
        }
      })
    })

    builder.addCase(setOrderContacts.fulfilled, (state, { payload }) => {
      state.contactsOrder = payload.contactsOrder
    })
    builder.addCase(setOrderContactsInProject.fulfilled, (state, { payload }) => {
      state.contactsOrderInProject = payload.contactsOrder
    })
    builder.addCase(getDepartments.rejected, () => {
      console.error('Error')
    })
    builder.addCase(getDepartments.fulfilled, (state, { payload }) => {
      state.contactsDepartment = payload
    })
    builder.addCase(getPositions.rejected, () => {
      console.error('Error')
    })
    builder.addCase(postPosition.rejected, () => {
      console.error('Error')
    })
    builder.addCase(postPosition.fulfilled, (state, { payload }) => {
      state.contactsPosition = [...state.contactsPosition, payload]
    })
    builder.addCase(getPositions.fulfilled, (state, { payload }) => {
      state.contactsPosition = payload
    })
    builder.addCase(postDepartment.rejected, () => {
      console.error('Error')
    })
    builder.addCase(postDepartment.fulfilled, (state, { payload }) => {
      state.contactsDepartment = [...state.contactsDepartment, payload]
    })
    builder.addCase(getTheProject.fulfilled, (state, { payload }) => {
      state.aboutProject = payload
      state.contactsProject = payload.contacts
      state.contactsOrderInProject = payload.docOrder
    })

    builder.addCase(postNewContactInProject.fulfilled, (state, { payload }) => {
      state.contactsProject = [...state.contactsProject, payload]
    })

    builder.addCase(putProjectNewContact.fulfilled, (state, { payload }) => {
      state.contactsProject = [...state.contactsProject, payload]
    })

    builder.addCase(updateProject.fulfilled, (state, { payload }) => {
      state.aboutProject = payload
    })
    builder.addCase(updateProjectContact.fulfilled, (state, { payload }) => {
      state.contactsProject = payload.contacts
    })

    builder.addCase(deleteProject.fulfilled, (state) => {
      state.aboutProject = {
        id: null,
        name: '',
        description: '',
        documentLogo: null,
        contacts: [],
        invites: undefined,
      }
      state.status = 'success'
    })

    builder.addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state) => {
        state.status = 'success'
        state.error = null
      },
    )
    builder.addMatcher(
      (action) => action.type.endsWith('/pending'),
      (state) => {
        state.status = 'loading'
      },
    )
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state) => {
        state.status = 'rejected'
      },
    )
  },
})
export const {
  setColorDepartment,
  setColorPosition,
  setContact,
  setContactsInNonExistingProject,
  setDefaultValues,
  onChangeProjectName,
  onChangeProjectDescription,
} = contactSlice.actions
export default contactSlice.reducer
