import { translationCallListComponentsEN, translationCallListComponentsRU } from './CallListComponents/index'
import translationBoardBodyEN from './BoardBody/en.json'
import translationBoardBodyRU from './BoardBody/ru.json'
import translationModalDeleteEN from './ModalDelete/en.json'
import translationModalDeleteRU from './ModalDelete/ru.json'
import translationLinkShareEN from './LinkShare/en.json'
import translationLinkShareRU from './LinkShare/ru.json'
import translationUIkitEN from './UI-kit/en.json'
import translationUIkitRU from './UI-kit/ru.json'
import { translationFilmCrewEN, translationFilmCrewRU } from './FilmCrew'
import { translationTablesEN, translationTablesRU } from './Tables'

export const translationComponentsEN = {
  ...translationBoardBodyEN,
  ...translationTablesEN,
  ...translationModalDeleteEN,
  ...translationLinkShareEN,
  ...translationCallListComponentsEN,
  ...translationFilmCrewEN,
  ...translationUIkitEN,
}
export const translationComponentsRU = {
  ...translationBoardBodyRU,
  ...translationTablesRU,
  ...translationModalDeleteRU,
  ...translationLinkShareRU,
  ...translationCallListComponentsRU,
  ...translationFilmCrewRU,
  ...translationUIkitRU,
}
