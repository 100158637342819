import translationLocationTableBodyEN from './LocationTableBody/en.json'
import translationLocationTableBodyRU from './LocationTableBody/ru.json'
import translationWeatherBlockEN from './WeatherBlock/en.json'
import translationWeatherBlockRU from './WeatherBlock/ru.json'
import translationSelectLocationEN from './SelectLocation/en.json'
import translationSelectLocationRU from './SelectLocation/ru.json'
import translationLocalTablesDragAndDropEN from './LocalTablesDragAndDrop/en.json'
import translationLocalTablesDragAndDropRU from './LocalTablesDragAndDrop/ru.json'

export const translationTablesComponentsEN = {
  ...translationLocationTableBodyEN,
  ...translationWeatherBlockEN,
  ...translationLocalTablesDragAndDropEN,
  ...translationSelectLocationEN,
}
export const translationTablesComponentsRU = {
  ...translationLocationTableBodyRU,
  ...translationWeatherBlockRU,
  ...translationLocalTablesDragAndDropRU,
  ...translationSelectLocationRU,
}
