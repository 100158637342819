import React from 'react'
import styles from './styles.module.scss'
import { socialLinksData } from 'pages/landingNew/lib/constants'

export const SocialIconLinks = () => {
  return (
    <ul className={styles.social}>
      {/* <a href='https://instagram.com/pre.pm?igshid=MmIxOGMzMTU=' target='_blank' rel='noreferrer'>
        <Images.Instagram />
      </a>
      <a href='https://www.youtube.com/channel/UCfRKQNrJ5muRKaPNPotgN9A' target='_blank' rel='noreferrer'>
        <Images.YouTube />
  </a> */}
      {socialLinksData.map((item) => (
        <li key={item.id} className={styles.social__item}>
          <a href={item.href} className={styles.social__link} target='_blank' rel='noreferrer'>
            <item.iconComponent className={styles.social__icon} />
          </a>
        </li>
      ))}
    </ul>
  )
}
