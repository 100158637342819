import { Modal } from '@material-ui/core'
import { Button } from 'components/Button'
import { BlankScreen } from 'components/UI/BlankScreen/BlankScreen'
import React, { useEffect, useState } from 'react'
import { ReactComponent as CloseIcon } from '../../assets/icons/close.svg'
import styles from './style.module.scss'

export const ErrorModal = () => {
  const [isOpen, setIsOpen] = useState(false)

  const onClickHandler = (): void => {
    document.location.reload()
  }

  const onCloseHandler = () => {
    setIsOpen((prev) => !prev)
  }

  useEffect(() => {
    window.addEventListener('offline', () => {
      setIsOpen(true)
    })
    window.addEventListener('online', () => {
      setIsOpen(false)
    })

    return () => {
      window.removeEventListener('offline', () => {
        setIsOpen(true)
      })
      window.removeEventListener('online', () => {
        setIsOpen(false)
      })
    }
  }, [])
  return (
    <Modal open={isOpen} onClose={onCloseHandler}>
      <div className={styles.wrapper}>
        <div className={styles.header}>
          Соединение с сервером потеряно <CloseIcon onClick={onCloseHandler} className={styles.header__icon} />
        </div>
        <BlankScreen
          imageConnectionLost
          title='Соединение с сервером потеряно'
          text='Мы приносим свои извинения
          и уже на пути к решению.'
        >
          <div className={styles.button_wrapper}>
            <Button onClick={onClickHandler} fullWidth={false} buttonType='button' type='yellow'>
              Перезагрузить страницу
            </Button>
          </div>
        </BlankScreen>
      </div>
    </Modal>
  )
}
