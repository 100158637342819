import { translationAddMemberNewEN, translationAddMemberNewRU } from './AddMemberNew/index'
import translationLunchSelectEN from './LunchSelect/en.json'
import translationLunchSelectRU from './LunchSelect/ru.json'
import translationAddColumnModalEN from './AddColumnModal/en.json'
import translationAddColumnModalRU from './AddColumnModal/ru.json'
import translationInteriorAndExteriorSelectEN from './InteriorAndExteriorSelect/en.json'
import translationInteriorAndExteriorSelectRU from './InteriorAndExteriorSelect/ru.json'

export const translationCallListComponentsEN = {
  ...translationAddColumnModalEN,
  ...translationLunchSelectEN,
  ...translationInteriorAndExteriorSelectEN,
  ...translationAddMemberNewEN,
}
export const translationCallListComponentsRU = {
  ...translationAddColumnModalRU,
  ...translationLunchSelectRU,
  ...translationInteriorAndExteriorSelectRU,
  ...translationAddMemberNewRU,
}
