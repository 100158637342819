import React from 'react'
import styles from './styles.module.scss'
import { NavLink } from 'react-router-dom'
import clsx from 'clsx'
import { ReactComponent as BtnCorner } from '../../assets/icons/btnCorner.svg'

interface INavButtonProps {
  link: string
  title: string
  size?: 'large'
  color?: 'yellow'
  onClick?: () => void
}

const NavButton = ({ link, title, color, onClick, size }: INavButtonProps) => {
  const cnLinkBtn = clsx(styles.linkBtn, {
    [styles.linkBtn_color_yellow]: color === 'yellow',
    [styles.linkBtn_size_large]: size === 'large',
  })
  const cnWrapper = clsx(styles.linkBtn__wrapper, styles.wrapper, {
    [styles.wrapper_color_yellow]: color === 'yellow',
  })

  const cnCornerTopRight = clsx(styles.wrapper__corner, styles.wrapper__corner_pos_topRight, {
    [styles.wrapper__corner_color_yellow]: color === 'yellow',
  })
  const cnCornerTopLeft = clsx(styles.wrapper__corner, styles.wrapper__corner_pos_topLeft, {
    [styles.wrapper__corner_color_yellow]: color === 'yellow',
  })
  const cnCornerBottomRight = clsx(styles.wrapper__corner, styles.wrapper__corner_pos_bottomRight, {
    [styles.wrapper__corner_color_yellow]: color === 'yellow',
  })
  const cnCornerBottomLeft = clsx(styles.wrapper__corner, styles.wrapper__corner_pos_bottomLeft, {
    [styles.wrapper__corner_color_yellow]: color === 'yellow',
  })
  return (
    <NavLink to={link} className={cnLinkBtn} onClick={() => onClick?.()}>
      <div className={cnWrapper}>
        <BtnCorner className={cnCornerTopRight} />
        <BtnCorner className={cnCornerTopLeft} />
        <BtnCorner className={cnCornerBottomRight} />
        <BtnCorner className={cnCornerBottomLeft} />
      </div>
      {title}
    </NavLink>
  )
}

export default NavButton
