import React from 'react'
import styles from './styles.module.scss'
import { paymentMethods } from 'pages/landingNew/lib/constants'

export const PaymentMethods = () => {
  return (
    <ul className={styles.payment}>
      {paymentMethods.map((item) => (
        <li key={item.id}>
          <item.iconComponent />
        </li>
      ))}
    </ul>
  )
}

export default PaymentMethods
