import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CrossBtn } from '../../../assets/icons/crossBtnIcon.svg'
import { ReactComponent as RubleIcon } from '../../../assets/icons/rubleIcon.svg'
import clsx from 'clsx'
import { ISubscriptionData } from 'pages/landingNew/lib/types'
import { NavLink } from 'react-router-dom'

interface ISubscriptionItemProps {
  data: ISubscriptionData
  isMobile: boolean
  isAbleToHover: boolean
}

const SubscriptionItem = ({ data, isMobile, isAbleToHover }: ISubscriptionItemProps) => {
  const { t } = useTranslation()
  const [isPriceBtnVisible, setIsPriceBtnVisible] = useState(isMobile || !isAbleToHover)

  useEffect(() => {
    setIsPriceBtnVisible(isMobile || !isAbleToHover)
  }, [isMobile, isAbleToHover])

  return (
    <div key={data.id} className={styles.item}>
      <div className={styles.item__leftSet}>
        <span className={clsx(styles.item__text, styles.offsetToTop)}>{`${t('new_subscription_period')}:`}</span>
        <span className={styles.item__text}>{`${t('new_subscription_price')}:`}</span>
      </div>
      <div className={styles.item__rightSet}>
        <div className={styles.item__rightSetTop}>
          <span className={clsx(styles.item__text, styles.item__text_bordered, styles.item__text_block_period)}>
            {t(data.localePeriod)}
          </span>
          <span
            className={clsx(
              styles.item__text,
              styles.item__text_bordered,
              styles.item__text_block_month,
              styles.offsetToLeft,
            )}
          >
            {t('new_subscription_month')}
          </span>
        </div>
        <div className={clsx(styles.item__rightSetBottom, styles.offsetToTop)}>
          <span className={clsx(styles.item__text, styles.item__text_bordered, styles.item__text_block_price)}>
            {t(data.localePrice)}
            <RubleIcon className={styles.item__currency} />
          </span>
          <div className={styles.item__priceWrapper}>
            {!isPriceBtnVisible && (
              <CrossBtn className={styles.item__priceCross} onMouseEnter={() => setIsPriceBtnVisible(true)} />
            )}
            <NavLink
              className={clsx(styles.item__priceBtn, !isPriceBtnVisible && styles.item__priceBtn_hidden)}
              onMouseLeave={() => {
                if (isMobile || !isAbleToHover) {
                  return
                }
                setIsPriceBtnVisible(false)
              }}
              to='/home'
            >
              {t('new_subscription_buy')}
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionItem
