import translationSidePanelEN from './SidePanel/en.json'
import translationSidePanelRU from './SidePanel/ru.json'
import translationTariffsEN from './landing/Tariffs/en.json'
import translationTariffsRU from './landing/Tariffs/ru.json'
import translationUserProfileEN from './UserProfile/en.json'
import translationUserProfileRU from './UserProfile/ru.json'
import translationRegistryFormEN from './RegistryForm/en.json'
import translationRegistryFormRU from './RegistryForm/ru.json'
import translationHeaderAuthFormEN from './HeaderAuth/en.json'
import translationHeaderAuthFormRU from './HeaderAuth/ru.json'
import { translationHomeMergeEN, translationHomeMergeRU } from './home'
// старый лендинг
import { translationLandingMergeEN, translationLandingMergeRU } from './landing'
// новый лендинг
import { translationLandingMergeENNew, translationLandingMergeRUNew } from './landingNew'
// --------------------------------------------------------------------------
import { translationComponentsEN, translationComponentsRU } from './components'
import { translationUIMergeEN, translationUIMergeRU } from './UI'
import { translationMessageMergeEN, translationMessageMergeRU } from './message'
import { translationCallListEN, translationCallListRU } from './callLIst'
import { translationIsAuthMergeEN, translationIsAuthMergeRU } from './isAuth'
import { translationStoryboardEN, translationStoryboardRU } from './storyboard'
import { translationShootingPlanEN, translationShootingPlanRU } from './shootingPlan'

export const translationMergeEN = {
  ...translationComponentsEN,
  ...translationTariffsEN,
  // старый лендинг
  ...translationLandingMergeEN,
  ...translationHomeMergeEN,
  ...translationIsAuthMergeEN,
  ...translationSidePanelEN,
  ...translationUserProfileEN,
  ...translationRegistryFormEN,
  ...translationHeaderAuthFormEN,
  ...translationUIMergeEN,
  ...translationMessageMergeEN,
  ...translationStoryboardEN,
  ...translationCallListEN,
  ...translationShootingPlanEN,
  // новый лендинг
  ...translationLandingMergeENNew,
  // ------------------------------------
}
export const translationMergeRU = {
  ...translationComponentsRU,
  ...translationTariffsRU,
  // старый лендинг
  ...translationLandingMergeRU,
  ...translationHomeMergeRU,
  ...translationIsAuthMergeRU,
  ...translationSidePanelRU,
  ...translationUserProfileRU,
  ...translationRegistryFormRU,
  ...translationHeaderAuthFormRU,
  ...translationUIMergeRU,
  ...translationMessageMergeRU,
  ...translationStoryboardRU,
  ...translationCallListRU,
  ...translationShootingPlanRU,
  // новый лендинг
  ...translationLandingMergeRUNew,
  // ------------------------------------
}
