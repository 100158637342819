import { ISbdFrame, IShpFrame } from 'pages/shootingPlan/helpers/types'

export const getSbdFrames = (shpFrames: IShpFrame[]): ISbdFrame[] => {
  return shpFrames.map((shpFrame: IShpFrame) => {
    return shpFrame.sbdframe
  })
}

export const framesOrder = (frames: any[], order: number[]) => {
  return [...frames].sort((a, b) => {
    if (order.indexOf(a.id) < 0) {
      return 1
    } else {
      return order.indexOf(a.id) - order.indexOf(b.id)
    }
  })
}

export const parseTimeToMinutes = (time: string): number => {
  const timeArr = time.split(':').map((time) => Number(time))
  const result = timeArr[0] * 60 + timeArr[1]

  return result
}

const parseSecondsToTime = (minutes: number): string => {
  const seconds = minutes * 60
  const date = new Date(seconds * 1000)
  const hh = date.getUTCHours().toString().padStart(2, '0')
  const mm = date.getUTCMinutes().toString().padStart(2, '0')
  const resultTimeString = `${hh}:${mm}`
  return resultTimeString
}

export const calculateTimeClosure = (
  dayStart: string,
): ((frameDuration: string, arrLength: number) => { start: string; end: string }) => {
  const timeRegexp = /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/

  if (dayStart && timeRegexp.test(dayStart)) {
    const startTimeInSeconds = parseTimeToMinutes(dayStart)
    let nextStartTime = startTimeInSeconds
    let counter = 1
    return (frameDuration, arrLength) => {
      if (counter <= arrLength) {
        const time = {
          start: '',
          end: '',
        }
        const frameDurationInSeconds = parseTimeToMinutes(frameDuration)
        if (nextStartTime === startTimeInSeconds) {
          time.start = dayStart
          time.end = parseSecondsToTime(startTimeInSeconds + frameDurationInSeconds)
          nextStartTime = startTimeInSeconds + frameDurationInSeconds
          counter++
        } else {
          time.start = parseSecondsToTime(nextStartTime)
          time.end = parseSecondsToTime(nextStartTime + frameDurationInSeconds)
          nextStartTime = nextStartTime + frameDurationInSeconds
          counter++
        }
        return time
      } else {
        return { start: '00:00', end: '00:00' }
      }
    }
  } else {
    return () => {
      console.error(`Wrong dayStart format. Expected: 'hh:mm', received: ${dayStart}`)
      return { start: '00:00', end: '00:00' }
    }
  }
}

export const numDeclension = (value: number, words: string[]) => {
  value = Math.abs(value) % 100
  const num = value % 10
  if (value > 10 && value < 20) {
    return words[2]
  }
  if (num > 1 && num < 5) {
    return words[1]
  }
  if (num === 1) {
    return words[0]
  }
  return words[2]
}

export const getTimeString = (min: number, t: any) => {
  const hours = Math.trunc(min / 60)

  const hoursString = numDeclension(hours, [
    t('shootingPlan_bottompanel_hour'),
    t('shootingPlan_bottompanel_hours'),
    t('shootingPlan_bottompanel_hours_2'),
  ])
  const minutes = Math.trunc(min % 60)
  const minutesString = numDeclension(minutes, [
    t('shootingPlan_bottompanel_мinute'),
    t('shootingPlan_bottompanel_мinutes'),
    t('shootingPlan_bottompanel_мinutes_2'),
  ])
  return `${hours} ${hoursString} ${minutes} ${minutesString}`
}
