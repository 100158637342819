import translationHeaderEN from './Header/en.json'
import translationHeaderRU from './Header/ru.json'
import translationStartScreenEN from './StartScreen/en.json'
import translationStartScreenRU from './StartScreen/ru.json'
import translationFunctionScreenRU from './FunctionScreen/ru.json'
import translationFunctionScreenEN from './FunctionScreen/en.json'
import translationSubscriptionScreenRU from './SubscriptionScreen/ru.json'
import translationSubscriptionScreenEN from './SubscriptionScreen/en.json'
import translationFooterScreenRU from './FooterScreen/ru.json'
import translationFooterScreenEN from './FooterScreen/en.json'

export const translationLandingMergeENNew = {
  ...translationHeaderEN,
  ...translationStartScreenEN,
  ...translationFunctionScreenEN,
  ...translationSubscriptionScreenEN,
  ...translationFooterScreenEN,
}
export const translationLandingMergeRUNew = {
  ...translationHeaderRU,
  ...translationStartScreenRU,
  ...translationFunctionScreenRU,
  ...translationSubscriptionScreenRU,
  ...translationFooterScreenRU,
}
