import React, { useEffect, useRef, useState } from 'react'
import styles from './styles.module.scss'
import { Portal } from 'components/UI-kit/Portal/Portal'
import { ReactComponent as CrossBtn } from '../../assets/icons/crossBtnIcon.svg'

interface IModalVideoProps {
  id: string
  src: string
  isPaused: boolean
  onClose: (id: string) => void
  sequenceNum: number
}
const ModalVideo = ({ id, src, isPaused, onClose, sequenceNum = 0 }: IModalVideoProps) => {
  const videoRef = useRef<HTMLVideoElement>(null)

  const [positionStyles, setPositionStyles] = useState({
    transform: `translate3d(calc(-50% - (28px * ${sequenceNum})), calc(-50% + (26px * ${sequenceNum})), 0)`,
  })

  useEffect(() => {
    const posStyles = {
      transform: `translate3d(calc(-50% - (28px * ${sequenceNum})), calc(-50% + (26px * ${sequenceNum})), 0)`,
    }
    setPositionStyles(posStyles)
  }, [sequenceNum])

  useEffect(() => {
    if (isPaused) {
      videoRef.current.pause()
    }
    if (!isPaused) {
      videoRef.current.play()
    }
  }, [isPaused])

  return (
    <Portal>
      <div className={styles.modal} style={positionStyles}>
        <button className={styles.modal__closeBtn} onClick={() => onClose(id)}>
          <CrossBtn className={styles.modal__closeBtnIcon} />
        </button>
        <div className={styles.modal__videoWrapper}>
          <video ref={videoRef} className={styles.modal__video} preload='auto' autoPlay loop playsInline muted>
            <source src={src} type='video/mp4' />
          </video>
        </div>
      </div>
    </Portal>
  )
}

export default ModalVideo
