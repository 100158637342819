import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import { api } from 'app/api'
import { apiTypes } from 'app/apiTypes'
import { AddNewDoc, DeleteDoc, UpdateDoc, UpdateText } from './allProjectActionTypes'

export const getProjects = createAsyncThunk('allProjects/getProjects', async () => {
  const response = await api.get<any>('projects/')
  return response.data
})
export const setNewOrderInProject = createAsyncThunk('allProjects/setNewOrderInProject', async (data: any) => {
  const response = await api.patch<any>(`projects/${data.projectId}/`, data.data)
  return response.data
})
// TODO проверить необходимость
export const postNewDocument = createAsyncThunk('allProjects/postNewDocument', async (payload: any) => {
  const response = await api.post<any>(`projects/${payload.projectId}/${payload.docType}s/`, {
    name: payload.docName,
    date: payload.date,
    url: payload.url,
    tagColor: payload.color,
    file: payload.file,
    hostProject: payload.projectId,
  })
  return response.data
})

export const postNewText = createAsyncThunk('allProjects/postNewText', async (payload: any) => {
  const response = await api.post<any>(`projects/${payload.projectId}/${payload.docType}s/`, {
    text: payload.text,
    tagColor: payload.color,
  })
  return response.data
})

export const updateDoc = createAsyncThunk('allProjects/updateDoc', async (data: any) => {
  const response = await api.patch<any>(`projects/${data.projectId}/${data.docType}s/${data.docId}/`, {
    name: data.docName,
    date: data.date,
    url: data.url,
    tagColor: data.color,
    file: data.file,
    text: data.text,
  })
  return response.data
})

export const moveDoc = createAsyncThunk('allProjects/moveDoc', async (data: any) => {
  const response = await api.patch<any>(`projects/${data.currentProjectId}/${data.docType}s/${data.docId}/move/`, {
    projectId: data.projectId,
    model: data.docType,
    objectId: data.docId,
  })
  return response.data
})

export const copyDoc = createAsyncThunk('allProjects/copyDoc', async (data: any) => {
  const response = await api.patch<any>(`projects/${data.currentProjectId}/${data.docType}s/${data.docId}/copy/`, {
    projectId: data.currentProjectId,
    model: data.docType,
    objectId: data.docId,
  })
  return response.data
})

export const updateDocLogoByUrl = createAsyncThunk(
  'allProjects/updateDocLogoByUrl',
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await api.patch<any>(`projects/${data.projectId}/${data.docType}s/${data.docId}/`, {
        documentLogoUrl: data.url,
      })
      return response.data
    } catch (error) {
      return rejectWithValue(error.message)
    }
  },
)

export const postNewDocumentFile = createAsyncThunk('allProjects/postNewDocument', async (payload: any) => {
  const response = await api.sendFile<any>(`projects/${payload.projectId}/${payload.docType}s/`, payload.formData)
  return response.data
})

export const updateDocFile = createAsyncThunk('allProjects/updateDoc', async (data: any) => {
  const response = await api.updateFile<any>(
    `projects/${data.projectId}/${data.docType}s/${data.docId}/`,
    data.formData,
  )
  return response.data
})
// TODO проверить на необходимость
export const updateDocLogo = createAsyncThunk('allProjects/updateDocLogo', async (data: any) => {
  const response = await api.updateFile<any>(`projects/document-logo/${data.docId}/`, data.formData)
  return response.data
})

export const getFileContent = createAsyncThunk('allProjects/getFileContent', async (data: any) => {
  const response = await api.getFile(`projects/${data.projectId}/files/${data.docId}/content/`)
  return response.data
})

export const getLinkPreview = createAsyncThunk('allProjects/getLinkPreview', async (link: any) => {
  const response = await api.post<any>(`url_opengraph/`, {
    url: link,
  })

  return {
    url: link,
    data: response.data,
  }
})

export const deleteDoc = createAsyncThunk('allProjects/deleteDoc', async (data: any) => {
  const response = await api.delete<any>(`projects/${data.projectId}/${data.docType}s/${data.docId}/`)
  return response.data
})

export const getInvitedUsers = createAsyncThunk('allProjects/getInvitedUsers', async (projectId: number) => {
  const response = await api.get<any>(`projects/${projectId}/share/`)
  return response.data
})

export const inviteUser = createAsyncThunk(
  'allProjects/inviteUser',
  async (data: { projectId: number; user: { email: string; perm: 'read' | 'edit'; message?: string } }) => {
    const response = await api.patch<any>(`projects/${data.projectId}/share/add/`, data.user)
    return response.data
  },
)

export const deleteInvitedUser = createAsyncThunk(
  'allProjects/deleteInvitedUser',
  async (data: { projectId: number; email: string }) => {
    const response = await api.patch<any>(`projects/${data.projectId}/share/del/`, { email: data.email })
    return response.data
  },
)

export const getProjectIcons = createAsyncThunk('allProjects/getProjectIcons', async () => {
  const response = await api.get<any>('icons/')
  return response.data
})

export const allProjects = createSlice({
  name: 'allProjects',
  initialState: {
    projects: [],
    searchResult: [],
    searchValue: '',
    previewData: [],
    isSearchMode: false,
    projectsShowDocs: [],
    status: null,
    docStatus: null,
    isOpenSidemenu: false,
    closeOnLeave: true,
    isOpenFilmCrew: false,
    isOpenModalEvent: false,
    selectDate: null,
    isClosePopover: false,
    isOpenProjectModal: false,
    selectedProjectId: null,
    isUserProfile: false,
    isPaymentModal: false,
    stylePopup: {},
    isDisplayPopup: false,
    popupTitle: '',
    icons: [],
    addEmail: false,
    changePassword: false,
    changeEmail: false,
    confirmEmailMode: false,
    confirmAddedEmailMode: false,
    replaceSocialMode: false,
  } as apiTypes.AllProjects,
  reducers: {
    filterProjects(state, { payload }) {
      state.projects = state.projects.filter((project) => project.id !== payload)
    },

    setProjectsShowDocs(state, { payload }) {
      state.projectsShowDocs = payload
    },

    setIsSearchMode(state) {
      state.isSearchMode = false
      state.searchValue = ''
    },

    setSearchValue(state, { payload }) {
      state.searchValue = payload
    },

    removeSearchResult(state) {
      state.searchResult = []
      state.isSearchMode = false
      state.searchValue = ''
    },

    setSearchResult(state) {
      const searchText = state.searchValue.toLowerCase()
      const newArray = JSON.parse(JSON.stringify(state.projects))

      const result = newArray.filter((project) => {
        const regex = new RegExp(searchText, 'i')

        if (regex.test(project.name.toLowerCase())) {
          return project
        } else {
          let hasTheProjectTargetDoc = false

          const keys = Object.keys(project.documents)

          keys.forEach((key) => {
            project.documents[key] = project.documents[key].filter((doc) => {
              if (regex.test(doc?.name?.toLowerCase())) {
                hasTheProjectTargetDoc = true
                return doc
              }
            })
          })

          if (hasTheProjectTargetDoc) {
            return project
          }
        }
      })

      state.isSearchMode = true
      state.searchResult = result
    },
    setIsOpenSidemenu(state, { payload }) {
      state.isOpenSidemenu = payload
    },
    setIsOpenSidemenuByBtn(state, { payload }) {
      state.isOpenSidemenu = payload
      state.closeOnLeave = !payload
    },
    setIsOpenFilmCrew(state, { payload }) {
      state.isOpenFilmCrew = payload
    },
    setIsOpenModalEvent(state, { payload }) {
      state.isOpenModalEvent = payload
    },
    setSelectDate(state, { payload }) {
      state.selectDate = payload
    },
    setIsClosePopover(state, { payload }) {
      state.isClosePopover = payload
    },
    setIsOpenProjectModal(state, { payload }) {
      state.isOpenProjectModal = payload
    },
    setSelectedProjectId(state, { payload }) {
      state.selectedProjectId = payload
    },
    setIsPaymentModal(state, { payload }) {
      state.isPaymentModal = payload
    },
    setIsUserProfile(state, { payload }) {
      state.isUserProfile = payload
    },
    setDocumentUuid(state, { payload }) {
      const { projectId, model, docId, docUuid } = payload
      const findProject = state.projects.find((pr) => pr.id === projectId)
      const correctProjectDocuments = state.projects[state.projects.indexOf(findProject)].documents
      const findStoryboard = correctProjectDocuments.storyboards.find((doc) => doc.id === docId)
      const findShootingplan = correctProjectDocuments.shootingplans.find((doc) => doc.id === docId)
      const findCallsheet = correctProjectDocuments.callsheets.find((doc) => doc.id === docId)
      switch (model) {
        case 'storyboard':
          correctProjectDocuments.storyboards[correctProjectDocuments.storyboards.indexOf(findStoryboard)].docUuid =
            docUuid
          break

        case 'shootingplan':
          correctProjectDocuments.shootingplans[
            correctProjectDocuments.shootingplans.indexOf(findShootingplan)
          ].docUuid = docUuid
          break

        case 'callsheet':
          correctProjectDocuments.callsheets[correctProjectDocuments.callsheets.indexOf(findCallsheet)].docUuid =
            docUuid
          break

        default:
          break
      }
    },
    setStylePopup(state, { payload }) {
      state.stylePopup = payload
    },
    setIsDisplayPopup(state, { payload }) {
      state.isDisplayPopup = payload
    },
    setPopupTitle(state, { payload }) {
      state.popupTitle = payload
    },
    addNewDoc(state, action: PayloadAction<AddNewDoc>) {
      const { projectId, docType, newDoc } = action.payload
      const id = typeof projectId === 'string' ? parseInt(projectId) : projectId
      const type = docType.toLowerCase()
      newDoc.model = type
      const docs = state.projects.find((el) => el.id === id)
      docs.documents[`${type}s`] = [...docs.documents[`${type}s`], newDoc]
    },
    updateDocLocal(state, action: PayloadAction<UpdateDoc>) {
      const { projectId, type, id, name } = action.payload
      const doc = state.projects.find((el) => el.id === projectId).documents[`${type}s`].find((el) => el.id === id)
      doc.name = name
    },
    updateIconLocal(state, { payload }) {
      const doc = state.projects
        .find((el) => el.id === payload.projectId)
        .documents[`${payload.docType}s`].find((el) => el.id === payload.docId)
      doc.documentLogo = payload.url
    },
    updateProjectIconLocal(state, { payload }) {
      const project = state.projects.find((el) => el.id === payload.projectId)
      project.documentLogo = payload.url
    },
    updateTextLocal(state, action: PayloadAction<UpdateText>) {
      const { projectId, type, id, text } = action.payload
      const doc = state.projects.find((el) => el.id === projectId).documents[`${type}s`].find((el) => el.id === id)
      doc.text = text
    },
    deleteDocLocal(state, action: PayloadAction<DeleteDoc>) {
      const { projectId, type, id } = action.payload
      const docs = state.projects.find((el) => el.id === projectId)
      docs.documents[`${type}s`] = docs.documents[`${type}s`].filter((el) => el.id !== id)
    },
    setAddEmail(state, { payload }) {
      state.addEmail = payload
    },
    setChangePassword(state, { payload }) {
      state.changePassword = payload
    },
    setChangeEmail(state, { payload }) {
      state.changeEmail = payload
    },
    setConfirmEmailMode(state, { payload }) {
      state.confirmEmailMode = payload
    },
    setConfirmAddedEmailMode(state, { payload }) {
      state.confirmAddedEmailMode = payload
    },
    setReplaceSocialMode(state, { payload }) {
      state.replaceSocialMode = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setNewOrderInProject.fulfilled, (state, { payload }) => {
      state.projects.map((propject) => {
        if (propject.id === payload.id) {
          propject.docOrder = payload.docOrder
        }
      })
    })
    builder.addCase(setNewOrderInProject.rejected, (state, { error }) => {
      throw new Error(error.message)
    })
    builder.addCase(getProjects.fulfilled, (state, { payload }) => {
      state.projects = payload
      state.status = 'success'
    })
    builder.addCase(getProjects.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(getProjects.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getProjectIcons.fulfilled, (state, { payload }) => {
      state.icons = payload
      state.status = 'success'
    })
    builder.addCase(getProjectIcons.rejected, (state) => {
      state.status = 'rejected'
    })
    builder.addCase(getProjectIcons.pending, (state) => {
      state.status = 'loading'
    })
    builder.addCase(getLinkPreview.fulfilled, (state, { payload }) => {
      state.previewData = [...state.previewData, payload]
    })

    builder.addCase(postNewDocument.fulfilled, (state) => {
      state.docStatus = 'success'
    })
    builder.addCase(postNewDocument.rejected, (state) => {
      state.docStatus = 'rejected'
    })
    builder.addCase(postNewDocument.pending, (state) => {
      state.docStatus = 'loading'
    })

    builder.addCase(updateDoc.fulfilled, (state) => {
      state.docStatus = 'success'
    })
    builder.addCase(updateDoc.rejected, (state) => {
      state.docStatus = 'rejected'
    })
    builder.addCase(updateDoc.pending, (state) => {
      state.docStatus = 'loading'
    })

    builder.addCase(deleteDoc.fulfilled, (state) => {
      state.docStatus = 'success'
    })
    builder.addCase(deleteDoc.rejected, (state) => {
      state.docStatus = 'rejected'
    })
    builder.addCase(deleteDoc.pending, (state) => {
      state.docStatus = 'loading'
    })

    builder.addCase(updateDocLogoByUrl.fulfilled, (state) => {
      state.docStatus = 'success'
    })
    builder.addCase(updateDocLogoByUrl.rejected, (state, error: any) => {
      state.docStatus = 'rejected'
      throw new Error(error.payload)
    })
    builder.addCase(updateDocLogoByUrl.pending, (state) => {
      state.docStatus = 'loading'
    })

    builder.addCase(moveDoc.fulfilled, (state) => {
      state.docStatus = 'success'
    })
    builder.addCase(moveDoc.rejected, (state) => {
      state.docStatus = 'rejected'
    })
    builder.addCase(moveDoc.pending, (state) => {
      state.docStatus = 'loading'
    })

    builder.addCase(copyDoc.fulfilled, (state) => {
      state.docStatus = 'success'
    })
    builder.addCase(copyDoc.rejected, (state) => {
      state.docStatus = 'rejected'
    })
    builder.addCase(copyDoc.pending, (state) => {
      state.docStatus = 'loading'
    })
  },
})

export const {
  setProjectsShowDocs,
  setSearchResult,
  setIsSearchMode,
  removeSearchResult,
  setSearchValue,
  setIsOpenSidemenu,
  setIsOpenSidemenuByBtn,
  setIsOpenFilmCrew,
  setIsOpenModalEvent,
  setSelectDate,
  setIsClosePopover,
  setIsOpenProjectModal,
  setSelectedProjectId,
  setIsPaymentModal,
  setIsUserProfile,
  filterProjects,
  setStylePopup,
  setIsDisplayPopup,
  setPopupTitle,
  setDocumentUuid,
  addNewDoc,
  updateDocLocal,
  deleteDocLocal,
  updateTextLocal,
  updateIconLocal,
  updateProjectIconLocal,
  setAddEmail,
  setChangePassword,
  setChangeEmail,
  setConfirmEmailMode,
  setConfirmAddedEmailMode,
  setReplaceSocialMode,
} = allProjects.actions
export default allProjects.reducer
