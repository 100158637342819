import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'
import { makeAnchorScroll } from 'helpers/makeAnchorScroll'
import { t } from 'i18next'
import NavButton from '../../NavButton/NavButton'
import { headerNavData } from 'pages/landingNew/lib/constants'

interface INavMenuProps {
  variant?: 'desktop' | 'mobile'
  onClick?: () => void
}

const NavMenu = ({ variant = 'desktop', onClick }: INavMenuProps) => {
  const isRegistryVisible = !!localStorage.getItem('JWT')

  return (
    <nav className={styles.nav}>
      <ul className={styles.nav__list}>
        {headerNavData.map((item) => {
          const { id, localeName, anchoreId, link } = item
          if (link === '/registry' && isRegistryVisible) {
            return null
          }
          if (anchoreId) {
            return (
              <li key={id} className={clsx(styles.nav__item, styles.nav__item_highlighted)}>
                <a
                  href={`#${anchoreId}`}
                  className={styles.nav__link}
                  onClick={(e) => {
                    e.preventDefault()
                    onClick?.()
                    makeAnchorScroll(`#${anchoreId}`)
                  }}
                >
                  {t(localeName)}
                </a>
              </li>
            )
          }
          if (link) {
            return (
              <li key={id} className={styles.nav__item}>
                <NavButton
                  link={link}
                  title={t(localeName)}
                  color={link === '/registry' ? 'yellow' : undefined}
                  size={variant === 'mobile' ? 'large' : undefined}
                  onClick={onClick}
                />
              </li>
            )
          }
        })}
      </ul>
    </nav>
  )
}

export default NavMenu
