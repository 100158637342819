import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'

import Header from './components/Header/Header'
import StartScreen from './components/StartScreen/StartScreen'
import FunctionScreen from './components/FunctionScreen/FunctionScreen'
import SubscriptionScreen from './components/SubscriptionScreen/SubscriptionScreen'
import FooterScreen from './components/FooterScreen/FooterScreen'
import { ID_LANDING_ROOT } from './lib/constants'

const fontsToNeedBeLoaded = [
  '400 74px BravoRG',
  '600 18px Source Sans Pro',
  '400 14px Source Sans Pro',
  '400 16px Source Sans Pro',
]

const PageLanding = () => {
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    fontsToNeedBeLoaded.forEach((font) => document.fonts.load(font))
    document.fonts.ready.then(() => {
      setIsReady(true)
    })
  }, [])

  return (
    isReady && (
      <>
        <div className={styles.container} id={ID_LANDING_ROOT}>
          <Header />
          <main className={styles.landing}>
            <StartScreen />
            <FunctionScreen />
            <SubscriptionScreen />
          </main>
          <FooterScreen />
        </div>
      </>
    )
  )
}

export default PageLanding
