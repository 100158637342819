import { createAsyncThunk } from '@reduxjs/toolkit'
import { api } from 'app/api'

export const getBordomatic = createAsyncThunk('bordomatic/getBordomatic', async (id: number) => {
  const { data } = await api.get(`bordomatic_private/${id}`)
  return data
})

export const postBordomatic = createAsyncThunk('bordomatic/postBordomatic', async (id: any) => {
  try {
    const { data } = await api.post(`bordomatic_private/`, { storyboard: id })
    return data
  } catch (error) {
    return new Error(error.message)
  }
})

export const uploadAudio = createAsyncThunk('bordomatic/uploadAudio', async (formData: any) => {
  try {
    const response = await api.sendFile(`save_audio/?bordomatic_id=10&video_type=private`, formData)
    return response.data
  } catch (error) {
    return new Error(error.message)
  }
})

export const deleteAudio = createAsyncThunk('bordomatic/deleteAudio', async (request: any) => {
  try {
    const response = await api.patch(`bordomatic_private/10/`, request)
    return response.data
  } catch (error) {
    return new Error(error.message)
  }
})

export const saveImages = createAsyncThunk('bordomatic/saveImages', async (formData: any) => {
  try {
    const response = await api.sendFile(`save_image/?bordomatic_id=10&video_type=private`, formData)
    return response.data
  } catch (error) {
    return new Error(error.message)
  }
})
