import { createSlice } from '@reduxjs/toolkit'
import { zoomIn, zoomOut, moveTop, moveRight, moveBottom, moveLeft } from '../images/index'
import { getBordomatic, postBordomatic, uploadAudio } from 'pages/bordomatic/redux/asyncActions'

const initialState: any = {
  bordomatic: {
    id: null,
    audios: [],
    fps: null,
    height: null,
    images: [],
    storyboard: null,
    uploadedAt: '',
    video: null,
    width: null,
  },
  activeSubtitles: false,
  durationFrame: 1000,
  indexBtnVideo: null,
  indexTimeline: null,
  isPlay: false,
  frameWidthPx: 62.63, // 1sec (px)
  slideIndex: 0,
  copyFrames: [],
  subtitles: [{ subtitle: '' }],
  settingsVideo: [
    { effectName: 'zoomIn', type: 'zoom', effectValue: 100, icon: zoomIn },
    { effectName: 'zoomOut', type: 'zoom', effectValue: 100, icon: zoomOut },
    { effectName: 'moveTop', type: 'move', effectValue: 1, icon: moveTop },
    { effectName: 'moveRight', type: 'move', effectValue: 1, icon: moveRight },
    { effectName: 'moveBottom', type: 'move', effectValue: 1, icon: moveBottom },
    { effectName: 'moveLeft', type: 'move', effectValue: 1, icon: moveLeft },
  ],
  slides: [
    {
      file: '',
      id: 0,
      effectName: '',
      effectValue: 0,
      duration: 1000,
      subtitle: [],
    },
  ],
}

const bordomaticSlice = createSlice({
  name: 'bordomatic',
  initialState,
  reducers: {
    setActiveSettingSubtitle(state) {
      state.activeSubtitles = !state.activeSubtitles
    },
    setActiveSettingVideo(state, action) {
      state.indexBtnVideo = action.payload
    },
    setSlides(state, action) {
      state.slides = action.payload
    },
    setSlideIndex(state, action) {
      state.slideIndex = action.payload
    },
    setDurationFrame(state, action) {
      state.durationFrame = action.payload
    },
    setIsPlay(state, action) {
      state.isPlay = action.payload
    },
    setCopyFrames(state, action) {
      state.copyFrames = action.payload
    },
    setIndexTimeline(state, action) {
      state.indexTimeline = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getBordomatic.fulfilled, (state, action) => {
      state.bordomatic = action.payload
    })
    builder.addCase(getBordomatic.rejected, (state, action) => {})
  },
})

export const {
  setActiveSettingVideo,
  setSlides,
  setSlideIndex,
  setDurationFrame,
  setIsPlay,
  setCopyFrames,
  setIndexTimeline,
} = bordomaticSlice.actions
export default bordomaticSlice.reducer

// eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjczMzQwNzA3LCJpYXQiOjE2NzA3NDg3MDcsImp0aSI6IjY3ZDZkMTlhNTlkODRlZjRiMzQxYjliMzAyNmNhZTAxIiwidXNlcl9pZCI6IjFmMDMyOTQzLTNmMDktNGNjZS1hZjYyLTFhOGQ4OWYxMGQxYyJ9.b1zIAQO_TmWTNp0resM141wDFp8Yv5lOqPPnFhPsXuM","refresh":"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTY5NjY2ODcwNywiaWF0IjoxNjcwNzQ4NzA3LCJqdGkiOiI2Y2E4ZWNmNDg4Mzg0NTg0YWI2MWU0NjVjMTNkNjNiNiIsInVzZXJfaWQiOiIxZjAzMjk0My0zZjA5LTRjY2UtYWY2Mi0xYThkODlmMTBkMWMifQ.UynILEncq2CNJf100ua4Z4xxkEqfWG3VwP7QwhF-wUI
