import {
  IEmailLinkInfo,
  IFooterDocsData,
  IFunctionData,
  IHeaderNavData,
  IPaymentMethod,
  ISocialLinksData,
  ISubscriptionData,
} from './types'
import privacyPolicy from '../../../assets/docs/RuPrivacyPolicy.pdf'
import userAgreement from '../../../assets/docs/RuUserAgreement.pdf'
import video from '../../../assets/video/firstscreenvideo.mp4'
import { ReactComponent as VkIcon } from '../assets/icons/vk.svg'
import { ReactComponent as TgIcon } from '../assets/icons/tg.svg'
import { ReactComponent as MirIcon } from '../assets/icons/mir.svg'
import { ReactComponent as VisaIcon } from '../assets/icons/visa.svg'
import { ReactComponent as MasterCardIcon } from '../assets/icons/master-card.svg'
import { ReactComponent as PaykeeperIcon } from '../assets/icons/paykeeper.svg'

export const ID_LANDING_ROOT = 'landing_root'
export const ID_HOW_IT_WORKS = 'landing_how-it-works'
export const ID_TARIFFS = 'landing_tariffs'

export const headerNavData: IHeaderNavData[] = [
  { id: 1, localeName: 'new_header_link_1', anchoreId: ID_HOW_IT_WORKS, link: null },
  { id: 2, localeName: 'new_header_link_2', anchoreId: ID_TARIFFS, link: null },
  { id: 3, localeName: 'new_header_btn', anchoreId: null, link: '/login' },
  { id: 4, localeName: 'new_header_btn_reg', anchoreId: null, link: '/registry' },
]

export const functionsData: IFunctionData[] = [
  { id: 1, localeTitle: 'function_title_1', localeDescription: 'function_text_1', src: video },
  { id: 2, localeTitle: 'function_title_2', localeDescription: 'function_text_2', src: video },
  { id: 3, localeTitle: 'function_title_3', localeDescription: 'function_text_3', src: video },
  { id: 4, localeTitle: 'function_title_4', localeDescription: 'function_text_4', src: video },
  { id: 5, localeTitle: 'function_title_5', localeDescription: 'function_text_5', src: video },
  { id: 6, localeTitle: 'function_title_6', localeDescription: 'function_text_6', src: video },
  { id: 7, localeTitle: 'function_title_7', localeDescription: 'function_text_7', src: video },
]

export const subscriptionData: ISubscriptionData[] = [
  { id: 1, localePeriod: 'new_subscription_month_value_1', localePrice: 'new_subscription_price_value_1' },
  { id: 2, localePeriod: 'new_subscription_month_value_2', localePrice: 'new_subscription_price_value_2' },
  { id: 3, localePeriod: 'new_subscription_month_value_3', localePrice: 'new_subscription_price_value_3' },
  { id: 4, localePeriod: 'new_subscription_month_value_4', localePrice: 'new_subscription_price_value_4' },
]

export const footerDocsData: IFooterDocsData[] = [
  { id: 1, href: privacyPolicy, fileName: 'Privacy_Policy_PPM_RU.pdf', linkLocaleName: 'footer_docs_link_1' },
  { id: 2, href: userAgreement, fileName: 'User_Agreement_PPM_RU.pdf', linkLocaleName: 'footer_docs_link_2' },
]

export const emailLinkInfo: IEmailLinkInfo = { href: 'mailto:info@pp-man.ru', text: 'info@pp-man.ru' }

export const socialLinksData: ISocialLinksData[] = [
  {
    id: 1,
    href: 'https://vk.com/ppmanager',
    iconComponent: VkIcon,
  },

  {
    id: 2,
    href: 'https://t.me/pp_manager',
    iconComponent: TgIcon,
  },
]

export const paymentMethods: IPaymentMethod[] = [
  {
    id: 1,
    iconComponent: MirIcon,
  },

  {
    id: 2,
    iconComponent: VisaIcon,
  },
  {
    id: 3,
    iconComponent: MasterCardIcon,
  },
  {
    id: 4,
    iconComponent: PaykeeperIcon,
  },
]
