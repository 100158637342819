import React from 'react'

export const moveTop = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='29' height='29' fill='none'>
    <g strokeLinecap='round' strokeLinejoin='round' strokeWidth='1.5' clipPath='url(#a)'>
      <path stroke='#fff' d='m11.3364 7.5103 2.9475-2.9475 2.9474 2.9474M14.2839 10.4577V4.5628' />
      <path
        stroke='#6A6E74'
        d='m17.2313 20.7739-2.9474 2.9475-2.9475-2.9475M14.2839 23.7214v-5.8949M20.9157 17.0895l2.9474-2.9474-2.9474-2.9475M17.9683 14.1421h5.8948M7.652 11.1946l-2.9474 2.9475 2.9474 2.9474M4.7046 14.1421h5.8949'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' d='M0 14.1421 14.1421 0 28.2843 14.142 14.142 28.2842z' />
      </clipPath>
    </defs>
  </svg>
)
