import React from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { ReactComponent as ItemSvg } from '../../assets/icons/itemIcon.svg'
import { ReactComponent as FunctionSvg } from '../../assets/icons/functionIcon.svg'
import { ReactComponent as SubscriptionSvg } from '../../assets/icons/subscriptionIcon.svg'
import clsx from 'clsx'

interface ISubtitlesProps {
  variant: 'function' | 'subscription'
}

const Subtitles = ({ variant }: ISubtitlesProps) => {
  const { t } = useTranslation()

  switch (variant) {
    case 'function':
      return (
        <div className={styles.block}>
          <h2 className={styles.block__title}>{t('function_title')}</h2>
          <div className={styles.block__wrapper}>
            <div className={clsx(styles.block__container, styles.container)}>
              <ItemSvg className={styles.container__item} />
              <FunctionSvg className={styles.container__function} />
            </div>
          </div>
        </div>
      )
    case 'subscription':
      return (
        <div className={styles.block}>
          <h2 className={styles.block__title}>{t('new_subscription_title')}</h2>
          <div className={styles.block__wrapper}>
            <p className={styles.block__description}>{t('subscription_text')}</p>
            <div className={clsx(styles.block__container, styles.container)}>
              <ItemSvg className={styles.container__item} />
              <SubscriptionSvg className={styles.container__subscription} />
            </div>
          </div>
        </div>
      )

    default:
      return null
  }
}

export default Subtitles
