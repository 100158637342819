import React from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import LeftDecor from './LeftDecor/LeftDecor'
import RightDecor from './RightDecor/RightDecor'
import NavButton from '../NavButton/NavButton'

const StartScreen = () => {
  const { t } = useTranslation()
  return (
    <section className={styles.start}>
      <div className={styles.start__wrapper}>
        <LeftDecor />
        <h1 className={styles.start__title}>{t('new_main_title')}</h1>
        <RightDecor />
      </div>
      <p className={styles.start__subtitle}>{t('new_main_subtitle')}</p>
      <NavButton title={t('new_button_try')} color='yellow' link='/registry' />
    </section>
  )
}

export default StartScreen
