import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from 'app/api'

export const getCallSheetMembersData = createAsyncThunk(
  'membersData/getCallSheetMembersData',
  async (params: any | undefined) => {
    const response = await api.get<any>(`projects/${params.pk}/callsheets/${params.id}/contacts/`)
    return response.data
  },
)

export const updateNewCallSheetContact: any = createAsyncThunk('contact/update', async function async(param: any) {
  const { id, data } = param
  const response = await api.patch(`contacts/${id}/`, data)
  return response.data
})

export const addMemberUserField = createAsyncThunk(
  'callSheetData/addMemberUserField',
  async (param: any | undefined) => {
    const { params, data, contactId } = param
    const response = await api.post<any>(
      `projects/${params.pk}/callsheets/${params.id}/members/${contactId}/usercolumns/`,
      {
        ...data,
      },
    )
    return response.data
  },
)

export const deleteMemberUserField = createAsyncThunk(
  'callSheetData/deleteMemberUserField',
  async (param: any | undefined) => {
    const { params, contactId, columnId } = param
    const response = await api.delete<any>(
      `projects/${params.pk}/callsheets/${params.id}/members/${contactId}/usercolumns/${columnId}/`,
    )
    return response.data
  },
)

export const updateMemberUserField = createAsyncThunk(
  'callSheetData/deleteMemberUserField',
  async (param: any | undefined) => {
    const { params, contactId, columnId, updatedData } = param
    const response = await api.patch<any>(
      `projects/${params.pk}/callsheets/${params.id}/members/${contactId}/usercolumns/${columnId}/`,
      updatedData,
    )
    return response.data
  },
)

export const updateMemberCellField = createAsyncThunk(
  'callSheetData/deleteMemberUserField',
  async (param: any | undefined) => {
    const { params, contactId, cellId, updatedData } = param
    const response = await api.patch<any>(
      `projects/${params.pk}/callsheets/${params.id}/members/${contactId}/usercolumns/${cellId}/`,
      updatedData,
    )
    return response.data
  },
)

export const membersSlice = createSlice({
  name: 'members',
  initialState: {
    callSheetMembers: [],
    checkedId: [],
  } as any,
  reducers: {
    setActive: (state, action) => {
      const { id, checked } = action.payload
      state.members = state.members.map((member) => (member.id === id ? { ...member, active: !checked } : member))
    },
    setChecked: (state, action) => {
      const id = action.payload
      const index = state.checkedId.findIndex((i) => i === id)
      if (index !== -1) {
        state.checkedId.splice(index, 1)
      } else {
        state.checkedId.push(id)
      }
    },
    resetChecked: (state) => {
      state.checkedId = []
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getCallSheetMembersData.fulfilled, (state, { payload }) => {
      state.callSheetMembers = payload
    })
  },
})

export const { setActive, setChecked, resetChecked } = membersSlice.actions

export default membersSlice.reducer
