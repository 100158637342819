import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiTypes } from 'app/apiTypes'
import { api } from 'app/api'

export const getUserProfile = createAsyncThunk('user/getUserProfile', async () => {
  const response = await api.get<any>(`auth/profile/`)
  return response.data
})

export const deleteUserProfile = createAsyncThunk('user/deleteUserProfile', async () => {
  const response = await api.delete<any>(`auth/profile/`)
  return response.data
})

export const changeUserPassword = createAsyncThunk('user/changeUserPassword', async (data: any) => {
  const response = await api.post<any>(`auth/password/change/`, data)
  return response.data
})

export const changeUserInfo = createAsyncThunk('user/changeUserInfo', async (data: any) => {
  const response = await api.patch<any>(`auth/profile/`, data)
  return response.data
})

export const changeUserEmail = createAsyncThunk('user/changeUserEmail', async (newEmail: string) => {
  const response = await api.post<apiTypes.UserData>(`auth/email/change/`, { new_email: newEmail })
  return response.data
})

export const verifyChangeEmail = createAsyncThunk('user/verifyChangeEmail', async ({ newEmail, code }: any) => {
  const response = await api.post<apiTypes.UserData>(`auth/email/change/confirm/`, { new_email: newEmail, code })
  return response.data
})

export const uploadAvatar: any = createAsyncThunk('user/uploadAvatar', async ({ avatar, onUploadProgress }: any) => {
  const response = await api.updateFileWithProgress<any>(`auth/profile/`, avatar, onUploadProgress)
  return response.data
})

export const registration = createAsyncThunk('user/registration', async (data: any) => {
  const response = await api.post<any>(`auth/signup/`, data)
  return response.data
})

export const login = createAsyncThunk('user/login', async (data: any) => {
  const response = await api.post<any>(`auth/login/`, data)
  return response.data
})

export const googleLogin = createAsyncThunk(
  'user/googleLogin',
  async (data: { provider: 'google-oauth2'; accessToken: string }) => {
    const response = await api.post<any>(`oauth/social-auth/`, data)
    return response.data
  },
)

export const googleDisconnect = createAsyncThunk(
  'user/googleDisconnect',
  async (data: { provider: 'google-oauth2' }) => {
    const response = await api.post<any>(`oauth/social-disconnect/`, data)
    return response.data
  },
)
export const vkDisconnect = createAsyncThunk('user/vkDisconnect', async (data: { provider: 'vk-oauth2' }) => {
  const response = await api.post<any>(`oauth/social-disconnect/`, data)
  return response.data
})
export const mailruDisconnect = createAsyncThunk('user/mailruDisconnect', async (data: { provider: 'mailru' }) => {
  const response = await api.post<any>(`oauth/social-disconnect/`, data)
  return response.data
})
export const yandexDisconnect = createAsyncThunk(
  'user/yandexDisconnect',
  async (data: { provider: 'yandex-oauth2' }) => {
    const response = await api.post<any>(`oauth/social-disconnect/`, data)
    return response.data
  },
)

export const vkLogin = createAsyncThunk(
  'user/vkLogin',
  async (data: { provider: 'vk-oauth2'; accessToken: string }) => {
    const response = await api.post<any>(`oauth/social-auth/`, data)
    return response.data
  },
)
export const mailRuLogin = createAsyncThunk(
  'user/mailRuLogin',
  async (data: { provider: 'mailru'; accessToken: string }) => {
    const response = await api.post<any>(`oauth/social-auth/`, data)
    return response.data
  },
)
export const yandexLogin = createAsyncThunk(
  'user/yandexLogin',
  async (data: { provider: 'yandex-oauth2'; accessToken: string }) => {
    const response = await api.post<any>(`oauth/social-auth/`, data)
    return response.data
  },
)

export const addEmail = createAsyncThunk('user/addEmail', async (data: { newEmail: string; newPassword: string }) => {
  const response = await api.post<any>(`auth/add_email/`, data)
  return response
})

export const confirmEmail = createAsyncThunk('user/confirmEmail', async (data: { code: string }) => {
  const response = await api.post<any>(`auth/add_email/confirm/`, data)
  return response
})

export const verifyEmail = createAsyncThunk('user/verifyEmail', async (data: any) => {
  const response = await api.post<any>(`auth/email/verify/`, data)
  return response.data
})

export const resendVerifyEmail = createAsyncThunk('user/resendVerifyEmail', async (email: string) => {
  const response = await api.post<any>(`auth/email/verify/resend/`, { email })
  return response.data
})

export const resetPassword = createAsyncThunk('user/resetPassword', async (email: string) => {
  const response = await api.post<any>(`auth/password/reset/`, { email })
  return response.data
})

export const tokenCheck = createAsyncThunk('user/tokenCheck', async (accessToken: string) => {
  const response = await api.post<any>(`auth/login/verify/`, { token: accessToken })
  return response.data
})

export const tokenRefresh = createAsyncThunk('user/tokenRefresh', async (refreshToken: string) => {
  const response = await api.post<any>(`auth/login/refresh/`, { refresh: refreshToken })
  return response.data
})

export const getDocUuid = createAsyncThunk('user/getDocUuid', async (data: { model: string; docId: number }) => {
  const response = await api.post<any>(`document/share/`, data)
  return response.data
})

export const getAnonymousToken = createAsyncThunk('user/getAnonymousToken', async (uuid: string) => {
  const response = await api.get<any>(`document/share/token/${uuid}/`)
  return response.data
})

export const deleteDocUuid = createAsyncThunk('user/getAnonymousToken', async (uuid: string) => {
  const response = await api.delete<any>(`document/share/${uuid}/`)
  return response.data
})
export const deleteBasket: any = createAsyncThunk('disk/deleteBasket', async function async(_, { dispatch }) {
  const response: any = await api.delete('trash/clean/')
  return response
})
interface UserSliceType {
  userData: {
    id: null | string
    firstName: null | string
    lastName: null | string
    email: null | string
    phone: null | string
    birthday: null | string
    avatar: null | string
    subscriptionEnd: number
    subscriptionTrial: boolean
    oauth2Providers: [] | Array<'google-oauth2' | 'vk-oauth2' | 'mailru' | 'yandex-oauth2'>
    changedPasswordDate: null | string
    userLanguage: null | 'ru' | 'eng'
    isVerified: boolean
    diskSpaceTotal: null | number
    diskSpace: null | number
  }
  addedEmail: boolean
  dealerId: any
  userId: any
  status: null | string
  dataForConfirm: {
    email: null | string
    password: null | string
  }
  role: 'guest' | 'user' | 'anonymous' | null
}

export const userSlice = createSlice({
  name: 'user',
  initialState: {
    userData: {
      id: null,
      firstName: null,
      lastName: null,
      email: null,
      phone: null,
      birthday: null,
      avatar: null,
      oauth2Providers: [],
      userLanguage: null,
      isVerified: false,
      diskSpaceTotal: null,
      diskSpace: null,
    },
    addedEmail: false,
    dealerId: null,
    userId: null,
    status: null,
    dataForConfirm: { email: null, password: null },
    role: null,
  } as UserSliceType,
  reducers: {
    setDealerId(state, { payload }) {
      state.dealerId = payload
    },
    setUserDataForConfirm(state, { payload }) {
      state.dataForConfirm.email = payload.email
      state.dataForConfirm.password = payload.password
    },
    setRole(state, { payload }) {
      state.role = payload
    },
    setStatus(state, { payload }) {
      state.status = payload
    },
    setUserEmail(state, { payload }) {
      state.userData.email = payload
    },
    setIsVerified(state, { payload }) {
      state.userData.isVerified = payload
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getUserProfile.fulfilled, (state, { payload }) => {
      state.userData = payload
    })

    builder.addCase(deleteUserProfile.fulfilled, (state) => {
      localStorage.removeItem('JWT')
      api.setUserToken('')
      state.role = 'guest'
    })

    builder.addCase(changeUserInfo.fulfilled, (state, { payload }) => {
      state.userData = payload
    })

    builder.addCase(uploadAvatar.fulfilled, (state, { payload }) => {
      state.userData = payload
    })

    builder.addCase(verifyEmail.fulfilled, (state, { payload }) => {
      if (payload.access) {
        const { access, refresh } = payload
        localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
        api.setUserToken(access)
      }
    })

    builder.addCase(addEmail.fulfilled, (state) => {
      state.addedEmail = true
    })

    builder.addCase(login.fulfilled, (state, { payload }) => {
      const { access, refresh } = payload
      localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
      api.setUserToken(access)
    })

    builder.addCase(googleLogin.fulfilled, (state, { payload }) => {
      state.userData.oauth2Providers = [...state.userData.oauth2Providers, 'google-oauth2']
      const { access, refresh } = payload
      localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
      api.setUserToken(access)
    })

    builder.addCase(vkLogin.fulfilled, (state, { payload }) => {
      state.userData.oauth2Providers = [...state.userData.oauth2Providers, 'vk-oauth2']
      const { access, refresh } = payload
      localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
      api.setUserToken(access)
    })

    builder.addCase(mailRuLogin.fulfilled, (state, { payload }) => {
      state.userData.oauth2Providers = [...state.userData.oauth2Providers, 'mailru']
      const { access, refresh } = payload
      localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
      api.setUserToken(access)
    })

    builder.addCase(yandexLogin.fulfilled, (state, { payload }) => {
      state.userData.oauth2Providers = [...state.userData.oauth2Providers, 'yandex-oauth2']
      const { access, refresh } = payload
      localStorage.setItem('JWT', JSON.stringify({ access, refresh }))
      api.setUserToken(access)
    })

    builder.addCase(googleDisconnect.fulfilled, (state) => {
      const updatedProviders = state.userData.oauth2Providers.filter((provider) => provider !== 'google-oauth2')
      state.userData.oauth2Providers = updatedProviders
    })

    builder.addCase(vkDisconnect.fulfilled, (state) => {
      const updatedProviders = state.userData.oauth2Providers.filter((provider) => provider !== 'vk-oauth2')
      state.userData.oauth2Providers = updatedProviders
    })

    builder.addCase(mailruDisconnect.fulfilled, (state) => {
      const updatedProviders = state.userData.oauth2Providers.filter((provider) => provider !== 'mailru')
      state.userData.oauth2Providers = updatedProviders
    })
    builder.addCase(yandexDisconnect.fulfilled, (state) => {
      const updatedProviders = state.userData.oauth2Providers.filter((provider) => provider !== 'yandex-oauth2')
      state.userData.oauth2Providers = updatedProviders
    })

    builder.addCase(tokenCheck.fulfilled, () => {
      const token = JSON.parse(localStorage.getItem('JWT')).access
      api.setUserToken(token)
    })

    builder.addCase(tokenRefresh.fulfilled, (state, { payload }) => {
      const refreshToken = JSON.parse(localStorage.getItem('JWT')).refresh
      localStorage.setItem('JWT', JSON.stringify({ access: payload.access, refresh: refreshToken }))
      api.setUserToken(payload.access)
      state.role = 'user'
    })

    // Rejected thunks
    builder.addCase(tokenRefresh.rejected, (state) => {
      localStorage.removeItem('JWT')
      state.role = 'guest'
    })

    builder.addCase(getAnonymousToken.rejected, (state, action) => {
      throw action.payload
    })

    builder.addMatcher(
      (action) => action.type.endsWith('/fulfilled'),
      (state) => {
        state.status = 'success'
      },
    )
    builder.addMatcher(
      (action) => action.type.endsWith('/pending'),
      (state) => {
        state.status = 'loading'
      },
    )
    builder.addMatcher(
      (action) => action.type.endsWith('/rejected'),
      (state) => {
        state.status = 'rejected'
      },
    )
  },
})

export const { setDealerId, setUserDataForConfirm, setRole, setStatus, setUserEmail, setIsVerified } = userSlice.actions

export default userSlice.reducer
