import React, { Suspense } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import Styles from './style.module.scss'
import toastStyle from './toastStyle.module.scss'
import { ErrorModal } from 'components/ErrorModal'
import { Spinner } from 'components/Spinner'

import 'react-toastify/dist/ReactToastify.css'
import { ToastContainer } from 'react-toastify'
// import PageLanding from 'pages/landing'
import PageLanding from 'pages/landingNew'
import { UIKit } from 'components/UI-kit/UIKit'

const HomePage = React.lazy(() => import('../../pages/home'))
const ShootingPlan = React.lazy(() => import('../../pages/shootingPlan'))
const Storyboard = React.lazy(() => import('../../pages/storyboard'))
const CallList = React.lazy(() => import('../../pages/callList'))
const AuthPage = React.lazy(() => import('../../pages/isAuth/index'))
const ConfirmEmail = React.lazy(() => import('../../pages/isAuth/components/ConfirmEmail'))
const PageNotFount = React.lazy(() => import('../../pages/not-found'))
const DevelopingPage = React.lazy(() => import('../../pages/developing'))
const AccessDeniedPage = React.lazy(() => import('../../pages/access-denied'))
const ProtectedRoute = React.lazy(() => import('components/Router/protected-route'))
const PublicRoute = React.lazy(() => import('components/Router/public-route'))
const Bordomatic = React.lazy(() => import('pages/bordomatic'))

const App = () => {
  const { pathname } = useLocation()

  return (
    <Suspense
      fallback={
        <div className={Styles.spinner_container}>
          <Spinner />
        </div>
      }
    >
      <div className={Styles.app_container}>
        <section className={Styles.section} style={pathname !== '/' ? { minWidth: '800px' } : undefined}>
          <ToastContainer
            style={{ zIndex: 999999999999999 }}
            toastClassName={({ type, defaultClassName }) =>
              toastStyle[type] ? `${toastStyle[type]} ${toastStyle.customToast}` : defaultClassName
            }
            bodyClassName={() => `${toastStyle.customBody}`}
          />
          <Switch>
            <Route path='/' exact component={PageLanding} />
            <ProtectedRoute allowedUsersTypes='user' path='/home' exact component={HomePage} />
            <Route path='/registry' exact component={AuthPage} />
            <Route path='/login' exact component={AuthPage} />
            <Route path='/reset' exact component={AuthPage} />
            <ProtectedRoute
              allowedUsersTypes='user'
              path='/shootingplan/:name/:pk/:id'
              exact
              component={ShootingPlan}
            />
            <PublicRoute
              allowedUsersTypes='anonymous'
              path='/public/shootingplan/:name/:pk/:id/:uuid'
              exact
              component={ShootingPlan}
            />
            <ProtectedRoute allowedUsersTypes='user' path='/storyboard/:name/:pk/:id' exact component={Storyboard} />
            <PublicRoute
              allowedUsersTypes='anonymous'
              path='/public/storyboard/:name/:pk/:id/:uuid'
              exact
              component={Storyboard}
            />
            <ProtectedRoute allowedUsersTypes='user' path='/storyboard/:name/:pk/:id' exact component={Storyboard} />
            <ProtectedRoute
              allowedUsersTypes='user'
              path='/storyboard/:name/:pk/:id/:id'
              exact
              component={Bordomatic}
            />
            <Route path='/confirm' exact component={ConfirmEmail} />
            <ProtectedRoute allowedUsersTypes='user' path='/callsheet/:name/:pk/:id' exact component={CallList} />
            <PublicRoute
              allowedUsersTypes='anonymous'
              path='/public/callsheet/:name/:pk/:id/:uuid'
              exact
              component={CallList}
            />
            <Route path='/agreements' exact component={DevelopingPage} />
            <Route path='/access-denied' exact component={AccessDeniedPage} />
            <Route path='/ui-playground' exact component={UIKit} />
            <Route path={['/*', '/404']} exact component={PageNotFount} />
          </Switch>
        </section>
        <ErrorModal />
        {/* <ModalAlert /> */}
      </div>
    </Suspense>
  )
}

export default App
