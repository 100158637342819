import React, { useEffect, useState } from 'react'
import styles from './styles.module.scss'
import Subtitles from '../Subtitles/Subtitles'
import { useTranslation } from 'react-i18next'
import { ReactComponent as CrossBtn } from '../../assets/icons/crossBtnIcon.svg'
import NavButton from '../NavButton/NavButton'
import { ID_TARIFFS, subscriptionData } from 'pages/landingNew/lib/constants'
import clsx from 'clsx'
import { useMediaQuery } from 'lib/hooks/useMediaQuery'
import SubscriptionItem from './SubscriptionItem/SubscriptionItem'

const SubscriptionScreen = () => {
  const isMobile = useMediaQuery('(max-width: 830px)')
  const isAbleToHover = useMediaQuery('(hover: hover)')
  const { t } = useTranslation()

  return (
    <section className={styles.subscription} id={ID_TARIFFS}>
      <div className={styles.subscription__wrapper}>
        <Subtitles variant='subscription' />
      </div>
      <ul className={styles.list}>
        {subscriptionData.map((item) => (
          <li key={item.id} className={styles.list__item}>
            <SubscriptionItem data={item} isMobile={isMobile} isAbleToHover={isAbleToHover} />
          </li>
        ))}
      </ul>

      <p className={styles.subscription__subtitle}>{t('new_subscription_subtitle')}</p>
      <NavButton title={t('new_subscription_button_try')} color='yellow' link='/registry' />
    </section>
  )
}

export default SubscriptionScreen
