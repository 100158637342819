import translationFilmCrewComponentsEN from './en.json'
import translationFilmCrewComponentsRU from './ru.json'
import translationAddButtonPersonEN from './AddButtonPerson/en.json'
import translationAddButtonPersonRU from './AddButtonPerson/ru.json'
import translationFilmCrewTableEN from './FilmCrewTable/en.json'
import translationFilmCrewTableRU from './FilmCrewTable/ru.json'
import translationContactInfoTableEN from './FilmCrewTable/TableRow/ContactInfoTable/en.json'
import translationContactInfoTableRU from './FilmCrewTable/TableRow/ContactInfoTable/ru.json'
import translationBottomTableEN from './FilmCrewTable/BottomTable/en.json'
import translationBottomTableRU from './FilmCrewTable/BottomTable/ru.json'

export const translationFilmCrewEN = {
  ...translationFilmCrewComponentsEN,
  ...translationAddButtonPersonEN,
  ...translationFilmCrewTableEN,
  ...translationContactInfoTableEN,
  ...translationBottomTableEN,
}
export const translationFilmCrewRU = {
  ...translationFilmCrewComponentsRU,
  ...translationAddButtonPersonRU,
  ...translationFilmCrewTableRU,
  ...translationContactInfoTableRU,
  ...translationBottomTableRU,
}
