import React from 'react'

export const moveRight = () => (
  <svg width='29' height='29' viewBox='0 0 29 29' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#clip0_3704_93213)'>
      <path
        d='M11.3359 7.51025L14.2834 4.56281L17.2308 7.51025'
        stroke='#6A6E74'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.2834 10.4577V4.56281'
        stroke='#6A6E74'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.2308 20.7739L14.2834 23.7214L11.3359 20.7739'
        stroke='#6A6E74'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M14.2834 23.7214V17.8265'
        stroke='#6A6E74'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M20.9152 17.0895L23.8627 14.1421L20.9152 11.1946'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M17.9678 14.1421H23.8627'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M7.65252 11.1946L4.70508 14.1421L7.65252 17.0895'
        stroke='#6A6E74'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M4.70508 14.1421H10.6' stroke='#6A6E74' strokeWidth='1.5' strokeLinecap='round' strokeLinejoin='round' />
    </g>
    <defs>
      <clipPath id='clip0_3704_93213'>
        <rect width='20' height='20' fill='white' transform='translate(0 14.1421) rotate(-45)' />
      </clipPath>
    </defs>
  </svg>
)
