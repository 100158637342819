import React from 'react'

export const decrement = () => (
  <svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='none'>
    <path
      stroke='#95999E'
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth='1.5'
      d='M8 3v9.3333M3.3335 8h9.3333'
    />
  </svg>
)
