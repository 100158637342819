import React from 'react'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { makeAnchorScroll } from 'helpers/makeAnchorScroll'

import { SocialIconLinks } from '../SocialIconLinks/SocialIconLinks'
import { ID_LANDING_ROOT, emailLinkInfo, footerDocsData } from 'pages/landingNew/lib/constants'
import clsx from 'clsx'
import { useMediaQuery } from 'lib/hooks/useMediaQuery'
import PaymentMethods from '../paymentMethods/paymentMethods'

const FooterScreen = () => {
  const { t } = useTranslation()
  const scrollTop = () => {
    makeAnchorScroll(`#${ID_LANDING_ROOT}`)
  }

  const isMobile = useMediaQuery('(max-width: 830px)')
  const isMobileLarge = useMediaQuery('(min-width: 651px) and (max-width: 830px)')
  const isMobileSmall = useMediaQuery('(max-width: 650px)')

  return (
    <footer className={styles.footer}>
      <div className={styles.footer__container}>
        <div className={styles.info}>
          {!isMobile && (
            <div className={styles.info__blockContainer}>
              <div onClick={scrollTop} className={styles.info__logo} />
              <PaymentMethods />
            </div>
          )}
          <nav className={styles.info__docs}>
            <p className={styles.info__title}>{t('footer_docs_title')}</p>
            <ul className={styles.info__docsList}>
              {footerDocsData.map((item) => (
                <li key={item.id} className={styles.info__docsItem}>
                  <a
                    href={item.href}
                    download={item.fileName}
                    rel='noreferrer'
                    target='_blank'
                    className={styles.info__link}
                  >
                    {t(item.linkLocaleName)}
                  </a>
                </li>
              ))}
              {isMobileLarge && <PaymentMethods />}
            </ul>
          </nav>

          <div className={styles.info__contacts}>
            <p className={styles.info__title}>{t('footer_contacts_title')}</p>

            <a href={emailLinkInfo.href} className={clsx(styles.info__link, styles.info__link_email)}>
              {emailLinkInfo.text}
            </a>
            <SocialIconLinks />
          </div>
          {isMobileSmall && <PaymentMethods />}
        </div>
        {isMobile && <div onClick={scrollTop} className={styles.info__logo} />}
        <p className={styles.footer__copyright}>&copy;PPM 2022</p>
      </div>
    </footer>
  )
}

export default FooterScreen
