import React from 'react'
import styles from './styles.module.scss'
import clsx from 'clsx'

const RightDecor = () => {
  const svgArray = [
    <svg
      key={0}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
    >
      <path
        d='M11.6746 10.1886H18.0002V11.7664H10.0994V7.04122H10.887V6.25642H11.6746V5.47162H15.6291V4.68681H16.4167V3.10903H15.6291V2.32423H12.4705V3.10903H11.6829V3.89384H10.0994V2.31606H10.887V1.53126H11.6746V0.746453H16.4167V1.53126H17.2044V2.31606H17.992V5.46344H17.2044V6.24824H16.4167V7.03305H12.4623V7.81785H11.6746V10.1804V10.1886ZM1.39464 10.1886H0.615234V8.61083H2.19867V9.39563H2.97807V10.1804H6.14494V9.39563H6.93256V7.81785H6.14494V7.03305H3.77389V5.45527H6.14494V4.67047H6.93256V3.09269H6.14494V2.30788H2.98628V3.09269H2.19867V3.87749H0.615234V2.29971H1.40285V1.51491H2.19046V0.730103H6.93256V1.51491H7.72017V2.29971H8.50778V5.44709H7.72017V7.02487H8.50778V10.1723H7.72017V10.9571H6.93256V11.7419H2.19046V10.9571H1.39464V10.1723V10.1886Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={1}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='12'
      viewBox='0 0 17 12'
      fill='none'
    >
      <path
        d='M14.8327 9.91883H16.4162V11.4966H11.6741V9.91883H13.2575V3.61589H11.6741V2.03811H12.4617V1.25331H13.2493V0.468504H14.8327V9.91883ZM1.38586 9.91883H0.606445V8.34106H2.18988V9.12586H2.96929V9.91066H6.13615V9.12586H6.92377V7.54808H6.13615V6.76328H3.76511V5.1855H6.13615V4.40069H6.92377V2.82291H6.13615V2.03811H2.97749V2.82291H2.18988V3.60772H0.606445V2.02994H1.39406V1.24513H2.18168V0.460327H6.92377V1.24513H7.71138V2.02994H8.499V5.17732H7.71138V6.7551H8.499V9.90248H7.71138V10.6873H6.92377V11.4721H2.18168V10.6873H1.38586V9.90248V9.91883Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={2}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
    >
      <path
        d='M11.6741 0.999757V0.214956H16.4162V0.999757H17.2038V1.78456H17.9914V9.65711H17.212V10.4501H16.4162V11.2267H11.6741V10.4419H10.8865V9.65711H10.0988V1.78456H10.8865V0.999757H11.6741ZM11.6741 8.08751V8.8723H12.4617V9.65711H15.6285V8.88049H16.408V4.93195H15.6203V5.71674H14.8327V6.50155H14.0451V7.28636H13.2575V8.07115H11.6741V8.08751ZM12.4617 6.50972V5.72493H13.2493V4.94012H14.0369V4.15532H14.8245V3.37052H16.408V2.58571H15.6203V1.80091H12.4617V2.58571H11.6741V6.52607H12.4617V6.50972ZM1.38586 9.65711H0.606445V8.07932H2.18988V8.86413H2.96929V9.64894H6.13615V8.86413H6.92377V7.28636H6.13615V6.50155H3.76511V4.92378H6.13615V4.13897H6.92377V2.56119H6.13615V1.77639H2.97749V2.56119H2.18988V3.34599H0.606445V1.76821H1.39406V0.98341H2.18168V0.198608H6.92377V0.98341H7.71138V1.76821H8.499V4.91559H7.71138V6.49338H8.499V9.64076H7.71138V10.4256H6.92377V11.2104H2.18168V10.4256H1.38586V9.64076V9.65711Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={3}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
    >
      <path
        d='M16.4167 5.67024V3.30766H15.6291V2.52285H12.4705V3.30766H11.6829V4.8936H12.4705V5.67024H16.4249H16.4167ZM10.887 10.3954H10.0994V8.81762H11.6829V9.60242H12.4705V10.3872H15.6291V9.60242H16.4167V7.23984H11.6746V6.46322H10.887V5.67024H10.0994V2.52285H10.887V1.73804H11.6746V0.953247H16.4167V1.73804H17.2044V2.52285H17.992V10.3954H17.2044V11.1802H16.4167V11.965H11.6746V11.1802H10.887V10.3954ZM2.19046 10.3954H8.51599V11.9732H0.615234V7.24801H1.40285V6.46322H2.19046V5.67841H6.14494V4.8936H6.93256V3.31583H6.14494V2.53102H2.98628V3.31583H2.19867V4.10062H0.615234V2.52285H1.40285V1.73804H2.19046V0.953247H6.93256V1.73804H7.72017V2.52285H8.50778V5.67024H7.72017V6.45503H6.93256V7.23984H2.97807V8.02464H2.19046V10.3872V10.3954Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={4}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
    >
      <path
        d='M15.6291 2.25307H12.4705V3.03788H11.6829V4.61565H12.4705V5.40046H15.6291V4.62383H16.4167V3.03788H15.6291V2.25307ZM16.4167 10.9186V11.7034H11.6746V10.9186H10.887V10.1338H10.0994V6.98642H10.887V5.40863H10.0994V2.26125H10.887V1.47645H11.6746V0.69165H16.4167V1.47645H17.2044V2.26125H17.992V5.40863H17.2044V6.20161V6.97823H18.0002V10.1256H17.2126V10.9104H16.4249L16.4167 10.9186ZM12.4623 10.1338H15.6209V9.349H16.4085V7.76304H15.6127V6.98642H12.4623V7.77121H11.6746V9.349H12.4623V10.1338ZM2.19046 10.1338H8.51599V11.7116H0.615234V6.98642H1.40285V6.20161H2.19046V5.41681H6.14494V4.632H6.93256V3.05423H6.14494V2.26942H2.98628V3.05423H2.19867V3.83903H0.615234V2.26125H1.40285V1.47645H2.19046V0.69165H6.93256V1.47645H7.72017V2.26125H8.50778V5.40863H7.72017V6.19344H6.93256V6.97823H2.97807V7.76304H2.19046V10.1256V10.1338Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={5}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
    >
      <path
        d='M16.4167 1.97793H10.0912V0.400146H17.992V4.34051H17.2044V5.12531H16.4167V5.9101H15.6291V6.69492H14.8415V11.4201H13.2581V5.9101H14.0457V5.12531H14.8333V4.34051H15.6209V3.55571H16.4085V1.97793H16.4167ZM2.19046 9.85047H8.51599V11.4282H0.615234V6.70308H1.40285V5.91829H2.19046V5.13349H6.14494V4.34868H6.93256V2.7709H6.14494V1.9861H2.98628V2.7709H2.19867V3.55571H0.615234V1.97793H1.40285V1.19312H2.19046V0.408317H6.93256V1.19312H7.72017V1.97793H8.50778V5.12531H7.72017V5.9101H6.93256V6.69492H2.97807V7.47972H2.19046V9.84229V9.85047Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={6}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
    >
      <path
        d='M12.4623 9.58875H15.6209V8.80395H16.4085V7.22618H15.6209V6.44138H11.6664V8.80395H12.4541V9.58875H12.4623ZM16.4167 10.3736V11.1584H11.6746V10.3736H10.887V9.58875H10.0994V1.71622H10.887V0.931402H11.6746V0.146606H16.4167V0.939585H17.2044V1.71622H17.992V3.294H16.4085V2.51736H15.6209V1.72438H12.4623V2.5092H11.6746V4.87177H16.4167V5.65656H17.2044V6.44138H17.992V9.58875H17.2044V10.3736H16.4167ZM2.19046 9.58875H8.51599V11.1665H0.615234V6.44138H1.40285V5.65656H2.19046V4.87177H6.14494V4.08697H6.93256V2.5092H6.14494V1.72438H2.98628V2.5092H2.19867V3.294H0.615234V1.71622H1.40285V0.931402H2.19046V0.146606H6.93256V0.931402H7.72017V1.71622H8.50778V4.86359H7.72017V5.64841H6.93256V6.4332H2.97807V7.218H2.19046V9.58059V9.58875Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={7}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
    >
      <path
        d='M10.0912 0.876709H17.992V2.45448H11.6664V4.03226H16.4085V4.81708H17.1961V5.60187H17.9838V10.327H17.1961V11.1118H16.4085V11.8966H11.6664V11.1118H10.8788V10.3189H10.0912V8.74108H11.6746V9.53406H12.4623V10.3189H15.6209V9.53406H16.4085V7.17146V6.38667H15.6291V5.60187H10.0994V0.876709H10.0912ZM2.19046 10.327H8.51599V11.9048H0.615234V7.17965H1.40285V6.39485H2.19046V5.61003H6.14494V4.82523H6.93256V3.24746H6.14494V2.46267H2.98628V3.24746H2.19867V4.03226H0.615234V2.45448H1.40285V1.66969H2.19046V0.884867H6.93256V1.66969H7.72017V2.45448H8.50778V5.60187H7.72017V6.38667H6.93256V7.17146H2.97807V7.95628H2.19046V10.3189V10.327Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={8}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
    >
      <path
        d='M14.8333 6.89634V3.74895H14.0457V4.53375H13.2581V5.31857H12.4705V6.10336H11.6829V6.88816H14.8415L14.8333 6.89634ZM14.8333 1.38638V0.601562H16.4167V6.90452H18.0002V8.4823H16.4167V11.6297H14.8333V8.4823H10.0912V5.33491H10.8788V4.55011H11.6664V3.76532H12.4541V2.9805H13.2417V2.1957H14.0293V1.41091H14.8169L14.8333 1.38638ZM2.19046 10.0519H8.51599V11.6297H0.615234V6.90452H1.40285V6.1197H2.19046V5.33491H6.14494V4.55011H6.93256V2.97234H6.14494V2.18752H2.98628V2.97234H2.19867V3.75713H0.615234V2.17936H1.40285V1.39454H2.19046V0.609746H6.93256V1.39454H7.72017V2.17936H8.50778V5.32673H7.72017V6.11155H6.93256V6.89634H2.97807V7.68114H2.19046V10.0437V10.0519Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={9}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
    >
      <path
        d='M10.8788 9.78199H10.0994V8.20422H11.6829V8.98902H12.4623V9.77381H15.6291V8.98902H16.4167V7.41124H15.6291V6.62645H13.2581V5.04865H15.6291V4.26385H16.4167V2.68608H15.6291V1.90128H12.4705V2.68608H11.6829V3.47088H10.0994V1.8931H10.887V1.10831H11.6746V0.323486H16.4167V1.10831H17.2044V1.8931H17.992V5.04049H17.2044V6.61826H17.992V9.76565H17.2044V10.5504H16.4167V11.3352H11.6746V10.5504H10.8788V9.76565V9.78199ZM2.19046 9.78199H8.51599V11.3598H0.615234V6.63461H1.40285V5.84981H2.19046V5.06501H6.14494V4.28019H6.93256V2.70242H6.14494V1.91763H2.98628V2.70242H2.19867V3.48724H0.615234V1.90944H1.40285V1.12465H2.19046V0.339852H6.93256V1.12465H7.72017V1.90944H8.50778V5.05683H7.72017V5.84163H6.93256V6.62645H2.97807V7.41124H2.19046V9.77381V9.78199Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={10}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
    >
      <path
        d='M11.6746 9.51223H18.0002V11.09H10.0994V6.36485H10.887V5.58005H11.6746V4.79523H15.6291V4.01044H16.4167V2.43266H15.6291V1.64787H12.4705V2.43266H11.6829V3.21746H10.0994V1.63968H10.887V0.854889H11.6746V0.0700684H16.4167V0.854889H17.2044V1.63968H17.992V4.78707H17.2044V5.57187H16.4167V6.35666H12.4623V7.14148H11.6746V9.50405V9.51223ZM2.19046 9.51223H8.51599V11.09H0.615234V6.36485H1.40285V5.58005H2.19046V4.79523H6.14494V4.01044H6.93256V2.43266H6.14494V1.64787H2.98628V2.43266H2.19867V3.21746H0.615234V1.63968H1.40285V0.854889H2.19046V0.0700684H6.93256V0.854889H7.72017V1.63968H8.50778V4.78707H7.72017V5.57187H6.93256V6.35666H2.97807V7.14148H2.19046V9.50405V9.51223Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={11}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='17'
      height='12'
      viewBox='0 0 17 12'
      fill='none'
    >
      <path
        d='M14.8333 10.2506H16.4167V11.8284H11.6746V10.2506H13.2581V3.94768H11.6746V2.36991H12.4623V1.58509H13.2499V0.800293H14.8333V10.2506ZM2.19046 10.2506H8.51599V11.8284H0.615234V7.10323H1.40285V6.31843H2.19046V5.53364H6.14494V4.74884H6.93256V3.17104H6.14494V2.38625H2.98628V3.17104H2.19867V3.95586H0.615234V2.37807H1.40285V1.59327H2.19046V0.808476H6.93256V1.59327H7.72017V2.37807H8.50778V5.52546H7.72017V6.31025H6.93256V7.09507H2.97807V7.87987H2.19046V10.2424V10.2506Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={12}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='18'
      height='12'
      viewBox='0 0 18 12'
      fill='none'
    >
      <path
        d='M11.6746 1.30992V0.525146H16.4167V1.30992H17.2044V2.09474H17.992V9.96729H17.2126V10.7603H16.4167V11.5369H11.6746V10.7521H10.887V9.96729H10.0994V2.09474H10.887V1.30992H11.6746ZM11.6746 8.3977V9.18247H12.4623V9.96729H15.6291V9.19065H16.4085V5.24215H15.6209V6.02692H14.8333V6.81174H14.0457V7.59651H13.2581V8.38133H11.6746V8.3977ZM12.4623 6.81992V6.0351H13.2499V5.25028H14.0375V4.46551H14.8251V3.68069H16.4085V2.89587H15.6209V2.1111H12.4623V2.89587H11.6746V6.83624H12.4623V6.81992ZM2.19046 9.96729H8.51599V11.5451H0.615234V6.81992H1.40285V6.0351H2.19046V5.25028H6.14494V4.46551H6.93256V2.88774H6.14494V2.10292H2.98628V2.88774H2.19867V3.67251H0.615234V2.09474H1.40285V1.30992H2.19046V0.525146H6.93256V1.30992H7.72017V2.09474H8.50778V5.24215H7.72017V6.02692H6.93256V6.81174H2.97807V7.59651H2.19046V9.95911V9.96729Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={13}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='12'
      viewBox='0 0 16 12'
      fill='none'
    >
      <path
        d='M14.4163 4.96684V2.60429H13.6287V1.81947H10.47V2.60429H9.68243V4.19025H10.47V4.96684H14.4245H14.4163ZM8.88661 9.69202H8.099V8.11425H9.68243V8.89902H10.47V9.68384H13.6287V8.89902H14.4163V6.53648H9.67422V5.75984H8.88661V4.96684H8.099V1.81947H8.88661V1.03465H9.67422V0.249882H14.4163V1.03465H15.2039V1.81947H15.9915V9.69202H15.2039V10.4768H14.4163V11.2616H9.67422V10.4768H8.88661V9.69202ZM3.3569 9.69202H4.94033V11.2698H0.198242V9.69202H1.78168V3.38906H0.198242V1.81129H0.985856V1.02647H1.77347V0.241699H3.3569V9.69202Z'
        fill='#FFFF00'
      />
    </svg>,
    <svg
      key={14}
      className={styles.svg}
      xmlns='http://www.w3.org/2000/svg'
      width='16'
      height='12'
      viewBox='0 0 16 12'
      fill='none'
    >
      <path
        d='M13.6291 2.55792H10.4704V3.34269H9.68282V4.92047H10.4704V5.70529H13.6291V4.92865H14.4167V3.34269H13.6291V2.55792ZM14.4167 11.2234V12.0082H9.67462V11.2234H8.887V10.4386H8.09939V7.29124H8.887V5.71347H8.09939V2.56605H8.887V1.78128H9.67462V0.996464H14.4167V1.78128H15.2043V2.56605H15.9919V5.71347H15.2043V6.50642V7.28306H16.0001V10.4305H15.2125V11.2152H14.4249L14.4167 11.2234ZM10.4622 10.4386H13.6209V9.65384H14.4085V8.06788H13.6127V7.29124H10.4622V8.07606H9.67462V9.65384H10.4622V10.4386ZM3.34909 10.4386H4.93253V12.0164H0.19043V10.4386H1.77386V4.1357H0.19043V2.55792H0.978043V1.7731H1.76566V0.988281H3.34909V10.4386Z'
        fill='#FFFF00'
      />
    </svg>,
  ]

  return (
    <div className={styles.decor}>
      <svg
        className={styles.svgLetter}
        xmlns='http://www.w3.org/2000/svg'
        width='9'
        height='12'
        viewBox='0 0 9 12'
        fill='none'
      >
        <path d='M3.76511 1.57778H0.606445V0H8.5072V1.57778H5.34854V11.0281H3.76511V1.57778Z' fill='#FFFF00' />
      </svg>
      <ul className={styles.decor__list}>
        {svgArray.map((item, ind) => (
          <li key={item.key} className={styles.decor__item}>
            <div className={clsx(styles.decor__line, ind === 0 && styles.decor__line_long)} />
            {item}
          </li>
        ))}
      </ul>
    </div>
  )
}

export default RightDecor
