import React from 'react'
import styles from './style.module.scss'

interface ICheckboxProps {
  checked?: boolean
  disabled?: boolean
  onClick?: () => void
}

export const Checkbox: React.FC<ICheckboxProps> = ({ checked, disabled, onClick }) => {
  return (
    <label>
      <input
        type='checkbox'
        className={styles.invisibleCheckbox}
        checked={checked}
        disabled={disabled}
        onChange={onClick}
      />
      <span className={styles.visibleCheckbox}>
        <div className={styles.visibleCheckbox__switch} />
      </span>
    </label>
  )
}
