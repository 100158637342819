import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { api } from 'app/api'
import { apiTypes } from '../helpers/apiTypes'
import { IShootingplanData, IUnit, IShpFrame } from '../helpers/types'
import { framesOrder } from 'pages/shootingPlan/helpers/utils'

export const getCurrentShootingplanData = createAsyncThunk(
  'shootingplan/getCurrentShootingplanData',
  async (data: apiTypes.CurrentShootingplan, { rejectWithValue }) => {
    try {
      const response = await api.get(`projects/${data.projectId}/shootingplans/${data.shootingplanId}/`)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response)
    }
  },
)

export const createUnit = createAsyncThunk('shootingplan/createUnit', async (data: apiTypes.CurrentShootingplan) => {
  const response = await api.post(`projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/`, {})
  return response.data
})

export const deleteUnit = createAsyncThunk('shootingplan/deleteUnit', async (data: apiTypes.CurrentUnit) => {
  const response = await api.delete(
    `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/`,
  )
  return response.data
})

export const getCurrentUnitData = createAsyncThunk(
  'shootingplan/getCurrentUnitData',
  async (data: apiTypes.CurrentUnit) => {
    const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/`
    const response = await api.get<IUnit>(url)
    return response.data
  },
)

export const addFramesFromStoryboard = createAsyncThunk(
  'shootingplan/addFramesFromStoryboard',
  async (data: apiTypes.AddFramesFromStoryboard) => {
    const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/`
    const response = await api.patch(url, data.ids)
    return response.data
  },
)

export const changeDayStart = createAsyncThunk('shootingplan/changeDayStart', async (data: apiTypes.ChangeDayStart) => {
  const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/`
  const response = await api.patch(url, data.newDayStart)
  return response.data
})

export const addNewShootingplanColumn = createAsyncThunk(
  'shootingplan/addNewShootingplanColumn',
  async (data: apiTypes.ColumnAdd, { rejectWithValue }) => {
    try {
      const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/frames/0/usercolumns/`
      const response = await api.post(url, data.newColumn)
      return response.data
    } catch (error) {
      return rejectWithValue(error.response.data)
    }
  },
)

export const addNewProcess = createAsyncThunk('shootingplan/addNewProcess', async (data: apiTypes.ProcessAdd) => {
  const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/frames/`
  const response = await api.post(url, data.newProcess)
  return response
})

export const changeExistColumn = createAsyncThunk(
  'shootingplan/changeExistColumn',
  async (data: apiTypes.ColumnChange) => {
    const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/frames/0/usercolumns/${data.columnId}/`
    const response = await api.patch(url, data.newColumn)
    return response.data
  },
)

export const changeExistUserfield = createAsyncThunk(
  'shootingplan/changeExistUserfield',
  async (data: apiTypes.UserfieldChange) => {
    const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/frames/${data.frameId}/`
    const response = await api.patch(url, data.newUserfield)
    return response.data
  },
)

export const uploadImage = createAsyncThunk('shootingplan/uploadImage', async (data: apiTypes.UploadImage) => {
  const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/frames/${data.frameId}/userfields/${data.userfieldId}/`
  const response = await api.patch(url, data.images)
  return response.data
})

export const deleteImage = createAsyncThunk('shootingplan/uploadImage', async (data: apiTypes.DeleteImage) => {
  const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/frames/${data.frameId}/userfields/${data.userfieldId}/images/${data.imageId}/`
  const response = await api.delete(url)
  return response.data
})

export const removeColumn = createAsyncThunk('shootingplan/removeColumn', async (data: apiTypes.ColumnRemove) => {
  const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/frames/${data.frameId}/usercolumns/${data.columnId}/`
  const response = await api.delete(url)
  return response.data
})

export const removeFrame = createAsyncThunk('shootingplan/removeFrame', async (data: apiTypes.FrameRemove) => {
  const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/frames/${data.frameId}/`
  await api.delete(url)
  return data.frameId
})

export const changeProcess = createAsyncThunk('shootingplan/changeProcess', async (data: apiTypes.ProcessChange) => {
  const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/frames/${data.frameId}/`
  const response = await api.patch(url, data.changedProcess)
  return response.data
})

export const reorderFrames = createAsyncThunk('shootingplan/reorderFrames', async (data: apiTypes.FramesReorder) => {
  const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/`
  const response = await api.patch(url, data.newOrder)
  return response.data
})

export const editUnitTitle = createAsyncThunk('shootingplan/editUnitTitle', async (data: apiTypes.EditUnitName) => {
  const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/`
  const response = await api.patch(url, { name: data.name })
  return response.data
})

export const changeShootingFrameDuration = createAsyncThunk(
  'shootingplan/changeShootingFrameDuration',
  async (data: apiTypes.changeFrameDuration) => {
    const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/units/${data.unitId}/frames/${data.frameId}/`
    const response = await api.patch(url, data.newDuration)
    return response.data
  },
)

export const changeShootingplanDescOrName = createAsyncThunk(
  'shootingplan/changeStoryboardDesc',
  async (data: apiTypes.ChangeShootingplanDesc) => {
    const url = `projects/${data.projectId}/shootingplans/${data.shootingplanId}/`
    const response = await api.patch(url, data.newData)
    return response.data
  },
)

export const shootingplanSlice = createSlice({
  name: 'shootingplan',
  initialState: {
    shootingplanData: {} as IShootingplanData,
    currentUnit: {} as IUnit,
  },
  reducers: {
    setShootingplanData(state, { payload }) {
      state.shootingplanData = { ...state.shootingplanData, ...payload }
    },
    setCurrentUnit(state, { payload }) {
      const choosenUnit = state.shootingplanData.units.find((unit) => unit.id === payload.id)
      choosenUnit.frames = framesOrder(choosenUnit.frames, choosenUnit.frameOrder)
      state.currentUnit = choosenUnit
    },
    setCurrentUnitFrames(state, { payload }) {
      state.shootingplanData.units.map((unit) => {
        if (unit.id === state.currentUnit.id) {
          unit.frames = payload
          return unit
        } else {
          return unit
        }
      })
      state.currentUnit.frames = payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(changeProcess.fulfilled, (state, { payload }: any) => {
      state.currentUnit.frames = state.currentUnit.frames.map((frame) => (frame.id === payload.id ? payload : frame))
    })
    builder.addCase(addFramesFromStoryboard.fulfilled, (state, { payload }: any) => {
      const result: IUnit = payload
      if (result.frames.length === 0) {
        return
      }
      result.frames = framesOrder(payload.frames, payload.frameOrder)
      state.shootingplanData.units = state.shootingplanData.units.map((unit) => {
        if (unit.id === result.id) {
          return result
        } else {
          return unit
        }
      })
      state.currentUnit = result
    })
    builder.addCase(addNewProcess.fulfilled, (state, { payload }) => {
      state.currentUnit.frames = [...state.currentUnit.frames, payload.data as IShpFrame]
    })
    builder.addCase(getCurrentUnitData.fulfilled, (state, { payload }) => {
      const result: IUnit = payload
      if (result.frames.length === 0) {
        return
      }
      result.frames = framesOrder(payload.frames, payload.frameOrder)
      state.currentUnit = result
    })
    builder.addCase(removeFrame.fulfilled, (state, { payload }) => {
      const copyFrames: IShpFrame[] = JSON.parse(JSON.stringify(state.currentUnit.frames))
      const framesWithoutDeleted = copyFrames.filter((frame) => frame.id !== payload)
      state.currentUnit.frames = framesWithoutDeleted
    })
    builder.addCase(getCurrentShootingplanData.fulfilled, (state, { payload }: any) => {
      state.shootingplanData = payload
      state.currentUnit = payload.units[0]
    })
    builder.addCase(getCurrentShootingplanData.rejected, (state, { payload }: any) => {
      throw payload
    })
    builder.addCase(changeShootingplanDescOrName.fulfilled, (state, { payload }: any) => {
      state.shootingplanData = payload
    })
    builder.addCase(changeShootingplanDescOrName.rejected, (state, { payload }: any) => {
      throw payload
    })
    builder.addCase(changeExistUserfield.fulfilled, (state, { payload }: any) => {
      const newFrames = state.currentUnit.frames.map((frame) => {
        if (frame.id !== payload.id) {
          return frame
        } else {
          return payload
        }
      })
      state.currentUnit.frames = newFrames
    })
    builder.addCase(createUnit.fulfilled, (state, { payload }: any) => {
      state.shootingplanData.units = [...state.shootingplanData.units, payload]
    })
    builder.addCase(changeDayStart.fulfilled, (state, { payload }: any) => {
      const result = { ...payload }
      result.frames = framesOrder(payload.frames, payload.frameOrder)
      state.currentUnit = result
      state.shootingplanData.units = state.shootingplanData.units.map((unit) => {
        if (unit.id === payload.id) {
          return payload
        } else {
          return unit
        }
      })
    })
    builder.addCase(reorderFrames.fulfilled, (state, { payload }: any) => {
      const result = { ...payload }
      result.frames = framesOrder(payload.frames, payload.frameOrder)
      state.currentUnit = result
      state.shootingplanData.units = state.shootingplanData.units.map((unit) => {
        if (unit.id === payload.id) {
          return result
        } else {
          return unit
        }
      })
    })
    builder.addCase(changeShootingFrameDuration.fulfilled, (state, { payload }: any) => {
      const copyFrames: IShpFrame[] = JSON.parse(JSON.stringify(state.currentUnit.frames))
      const editedFrames = copyFrames.map((frame) => {
        if (frame.id !== payload.id) {
          return frame
        } else {
          return payload
        }
      })
      state.currentUnit.frames = editedFrames
      state.shootingplanData.units = state.shootingplanData.units.map((unit) => {
        if (unit.id === state.currentUnit.id) {
          const editedUnit = unit
          editedUnit.frames = editedFrames
          return editedUnit
        } else {
          return unit
        }
      })
    })
  },
})

export const { setShootingplanData, setCurrentUnit, setCurrentUnitFrames } = shootingplanSlice.actions
export default shootingplanSlice.reducer
