import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'

import { Provider } from 'react-redux'
import App from './components/App'
import { store } from 'app/redux/store'
import { I18nextProvider } from 'react-i18next'
import i18n from './i18nest'
import { BrowserRouter as Router } from 'react-router-dom'
import { GoogleOAuthProvider } from '@react-oauth/google'

document.documentElement.classList.add('mts-theme', 'mts-theme-light')

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <Router>
        <GoogleOAuthProvider clientId={process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_OAUTH2_KEY}>
          <App />
        </GoogleOAuthProvider>
      </Router>
    </Provider>
  </I18nextProvider>,
  document.getElementById('root'),
)
