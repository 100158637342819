/* eslint-disable import/namespace */
/* eslint-disable import/default */
/* eslint-disable import/no-named-as-default-member */
import { configureStore } from '@reduxjs/toolkit'
import allProjects from '../../pages/home/redux/allProjects'
import userSlice from './userSlice'
import inputEvent from 'components/Input/redux/inputEvent'
import calendarSlice from 'components/Calendar/redux/CalendarSlice'
import tableSlice from '../../pages/callList/redux/tableSlice'
import membersSlice from '../../pages/callList/redux/membersSlice'
import paymentSlice from 'components/Payment/redux/paymentSlice'
import basketSlice from 'components/Sidemenu/components/Basket/redux/basketSlice'
import storyboardSlice from '../../pages/storyboard/redux/storyboardSlicer'
import shootingplanSlice from '../../pages/shootingPlan/redux/shootingPlanSlicer'
import bordomaticSlice from '../../pages/bordomatic/redux/bordmaticSlice'
import contactSlice from './contactSlice'
import diskSpaceSlice from './diskSpaceSlice'

export const store = configureStore({
  reducer: {
    allProjects,
    user: userSlice,
    input: inputEvent,
    calendar: calendarSlice,
    table: tableSlice,
    members: membersSlice,
    basket: basketSlice,
    payment: paymentSlice,
    storyboard: storyboardSlice,
    shootingplan: shootingplanSlice,
    contact: contactSlice,
    bordomatic: bordomaticSlice,
    disk: diskSpaceSlice,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
